$step-mobile-height: 220px;

#treasury-model-graphic-mobile {
  max-width: 300px;
  margin: 0 auto;
  display: none;
  @media (max-width: 545px) {
    display: block;
  }
  .step {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 3rem auto;
    padding-left: 70px;
    padding-right: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .text {
    order: 2;
    font-size: 1.2rem;
    text-align: left;
  }
  .icon {
    order: 1;
    img {
      display: block;
      height: 80px;
      margin-bottom: 1rem;
    }
  }
  .label {
    display: flex;
    position: absolute;
    top: 22px;
    left: 8px;
    // justify-content: center;
    // flex: 0 0 25%;
    // max-width: 25%;
    span {
      z-index: 2;
      font-size: 1.2rem;
      line-height: 33px;
      height: 36px;
      width: 36px;
      background-color: $darkblue;
      border-radius: 5rem;
      border: 3px solid $green;
    }
  }
}

#treasury-model-graphic {
  display: flex;
  flex-direction: column;
  max-width: 1660px;
  margin: 3rem auto;
  @media (max-width: 767px) {
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
  }
  @media (max-width: 545px) {
    display: none;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    &.first {
      .step {
        .text {
          order: 1;
          align-items: flex-end;
          @media (max-width: 767px) {
            order: 2;
            align-items: flex-start;
          }
        }
        .icon {
          order: 2;
          margin-top: 1rem;
          margin-bottom: 1.4rem;
          @media (max-width: 767px) {
            order: 1;
            margin-top: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }
    &.middle {
      @media (max-width: 767px) {
        max-width: 190px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &.last {
      .step {
        .text {
          order: 2;
        }
        .icon {
          order: 1;
          margin-top: 1.2rem;
          margin-bottom: 1rem;
          @media (max-width: 767px) {
            margin-top: 0;
            margin-bottom: 1rem;
          }
        }
      }
      @media (max-width: 767px) {
        margin-top: ($step-mobile-height/2);
        margin-bottom: ($step-mobile-height/2);
      }
    }
    @media (max-width: 767px) {
      flex: 0 0 (100%/3);
      max-width: (100%/3);
      margin-left: 0;
      margin-right: 0;
      &.middle {
        flex-direction: column;
      }
    }
  }

  .step {
    display: flex;
    flex-direction: column;
    flex: 0 0 25%;
    max-width: 25%;
    @media (max-width: 767px) {
      height: $step-mobile-height;
      justify-content: center;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .text {
      display: flex;
      flex-grow: 1;
      font-size: 1.2rem;
      max-width: 90%;
      margin: 0 auto;
      @media (max-width: 767px) {
        flex-grow: 0;
        font-size: 1rem;
      }
    }
    .icon {
      max-height: 120px;
      // max-width: 120px;
      margin-left: auto;
      margin-right: auto;
      img {
        display: block;
        height: 120px;
        margin: auto;
      }
      @media (max-width: 991px) {
        max-height: 80px;
        img {
          height: 80px;
        }
      }
    }

  }

  .label {
    display: flex;
    justify-content: center;
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    height: 100px;
    height: calc(56px + 2.2vw);
    @media (max-width: 1199px) {
      height: calc(50px + 2.2vw);
    }
    @media (max-width: 991px) {
      height: calc(45px + 2.2vw);
    }
    @media (max-width: 767px) {
      align-items: center;
      max-width: 100px;
    }
    &:nth-child(n+5) {
      align-items: flex-end;
      @media (max-width: 767px) {
        align-items: center;
        justify-content: flex-end;
      }
    }
    &:nth-child(-n+3) {
      &:after {
        content:"";
        position: absolute;
        z-index: 1;
        top: 24px;
        left: 51%;
        border-top: 3px dotted $green;
        width: 60%;
        transform: rotate(37deg);
        transform-origin: 0% 0%;
        @media (max-width: 767px) {
          top: 50%;
          left: 19px;
          width: 200%;
          transform: rotate(42deg);
        }
        @media (max-width: 545px) {
          display: none;
        }
      }
    }
    &:nth-child(-n+4) {
      @media (max-width: 767px) {
        justify-content: flex-start;
      }
    }
    &:nth-child(n+2):nth-child(-n+4) {
      &:before {
        content:"";
        position: absolute;
        z-index: 1;
        top: 24px;
        left: 50%;
        border-top: 3px dotted $green;
        width: 60%;
        transform: rotate(143deg);
        transform-origin: 0% 0%;
        @media (max-width: 767px) {
          top: 50%;
          left: 19px;
          width: 200%;
          transform: rotate(-42deg);
        }
        @media (max-width: 545px) {
          display: none;
        }
      }
    }
    span {
      z-index: 2;
      font-size: 1.2rem;
      line-height: 33px;
      height: 36px;
      width: 36px;
      background-color: $darkblue;
      border-radius: 5rem;
      border: 3px solid $green;
    }
  }
}
