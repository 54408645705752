* {
  -webkit-font-smoothing: auto;
}

h1, h2, h3, h4, h5, h6 {
  color: $green;
  text-transform: uppercase;
}
h1 {
  letter-spacing: 10px;
}
h2 {
  font-weight: 700;
  letter-spacing: 9px;
}
h3 {
  font-weight: 700;
  letter-spacing: 6px;
}

.base-font {
  font-family: proxima-nova,sans-serif !important;
}

.btn-document {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  text-align: left;
  color: $h-dark-blue;
  background-color: white;
  padding: 1.4rem;
  &:hover,
  &:focus {
    // color: white;
    background-color: rgba(white, 0.85);
  }
  // .icon {
  //   display: block;
  //   padding: 1rem;
  //   img {
  //     // width: 45px;
  //     // height: 54px;
  //   }
  // }
  .content {
    padding-left: 3.4rem;
    &:before {
      position: absolute;
      top: 50%;
      left: 1.5rem;
      display: block;
      content: '';
      height: 37px;
      width: 30px;
      margin-top: -18.5px;
      background: url('../img/icons/document.png');
      background-repeat: no-repeat;
    }
  }
  header {
    font-size: 1rem;
    font-weight: 100;
    text-transform: none;
    margin-bottom: 0.4rem;
  }
  main {
    font-size: 1rem;
    font-weight: 500;
    text-transform: none;
  }
}

// styles for countdown timer
#branding-countdown {
  h2 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 4px;
    @media (max-width: 575px) {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
  .countdown {
    .col {
      position: relative;
      max-width: 130px;
      @media (max-width: 767px) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @media (max-width: 575px) {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }

  $circleSize: 100px;
  .progress-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: $circleSize;
    width: $circleSize;
    padding: 10px;
    @media (max-width: 767px) {
      height: auto;
      width: auto;
      padding: 0;
    }
    h2 {
      font-size: 1.4rem;
      font-weight: 600;
      color: $green;
      margin-bottom: 0;
      @media (max-width: 575px) {
        font-size: 1.4rem;
      }
    }
    h4 {
      font-size: 0.7rem;
      font-weight: 300;
      letter-spacing: 2px;
      @media (max-width: 575px) {
        font-size: 0.7rem;
      }
    }
  }
  .progress-circle {
    position: absolute;
    width: $circleSize;
    height: $circleSize;
    top: 0;
    left: 50%;
    margin-left: -$circleSize / 2;
    @media (max-width: 767px) {
      display: none;
    }
  }
}
// .jumbotron .announcement-footer {
//   @media (max-width: 575px) {
//     padding-bottom: 0.5rem !important;
//   }
// }
// END styles for countdown timer

section:not(#hero) {
  position: relative;
  ul {
    position: relative;
    list-style-type: none;
    margin-left: 0;
    li {
      font-size: 22px;
      font-weight: 300;
      padding: .5rem 0;
      margin-left: 20px;
      &:before {
        position: absolute;
        margin-left: -20px;
        margin-top: -2px;
        content: "\2022";
      }
    }
  }
}

.btn {
  font-weight: 400;
  border: 0;
  border-radius: 0;
  text-transform: uppercase;
  text-shadow: none;
  white-space: normal;
}
.btn-lg {
  font-size: 14px;
  letter-spacing: 4px;
  padding: 24px 34px;
}
.btn-primary {
  color: white;
  background-color: $h-light-blue;
  &:hover,
  &:focus {
    color: white;
    background-color: $h-blue;
    box-shadow: none;
  }
}
.btn-secondary {
  color: $text-color;
  background-color: #f3f3f3;
  &:hover {
    color: $text-color;
    background-color: $green;
    box-shadow: none;
  }
}
.btn-inverse {
  color: $darkblue;
  font-weight: 600;
  background-color: transparent;
  border: 1px solid $darkblue;
  padding: 1.4rem 3rem;
  .right-arrow {
    width: 10px;
    transform: rotate(-90deg);
    margin-left: 8px;
    margin-top: -3px;
  }
  &:hover,
  &:focus {
    color: $darkblue;
    background-color: $green;
    border-color: $green;
  }
}
.btn-ghost {
  color: white;
  background-color: transparent;
  border: 2px solid white;
  &:hover,
  &:focus {
    color: $darkblue;
    background-color: white;
  }
}
.card {
  box-shadow: none;
}

.logo-text {
  font-weight: 100 !important;
  font-family: proxima-nova,sans-serif !important;
  text-transform: none;
  color: $darkblue;
  line-height: 1;
  word-wrap: break-word;
  strong {
    font-weight: 600 !important;
  }
}
.section-title {
  text-align: center;
}
h2.section-title {
  font-size: 20px;
  letter-spacing: 0.35rem;
  margin-bottom: 4rem;
}
.subhead-large {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 42px;
  line-height: 48px;
  font-weight: 100;
}
.lined-subtext {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 5px;
  font-weight: 100;
  color: #185e94;
  margin-top: 1rem;
  text-align: center;
  &:before,
  &:after {
    display: inline-block;
    content: '';
    height: 1px;
    width: 12vw;
    background-color: #185e94;
  }
  &:before {
    margin-right: 20px;
  }
  &:after {
    margin-left: 15px;
  }
}

.site-header {
  z-index: 100;
  position: fixed;
  width: 100%;
  -webkit-backface-visibility: hidden;
  .navbar {
    padding: .5rem 30px;
  }
  .navbar-brand {
    font-size: 1rem;
    line-height: 1;
    max-width: 160px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .nav-item {
    &:not(:last-of-type) {
      .nav-link {
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          top: 50%;
          display: block;
          content: '';
          width: 2px;
          height: 18px;
          margin-top: -9px;
          background-color: rgba($h-light-blue, 0.5);
        }
      }
    }
    &:first-of-type {
      .nav-link {
        &:before {
          display: none;
        }
      }
    }
  }
  .navbar-brand,
  .nav-link {
    color: white;
    font-weight: 400;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $h-light-blue;
    }
    // &:not(.toggle-languages) {
    //   text-transform: lowercase;
    // }
  }
  @media (max-width: 767px) {
    &.headroom--not-top {
      .navbar-brand {
        padding-bottom: 4px;
      }
    }
    .navbar {
      padding-left: 0;
      padding-right: 0;
    }
    .navbar-brand {
      padding-left: 15px;
      padding-bottom: 10px;
    }
    .navbar-collapse {
      padding: 0 15px;
      background-color: darken($orange, 5%);
      // .nav-item:first-of-type {
      //   padding-top: 15px;
      // }
      .nav-item:last-of-type {
        padding-bottom: 15px;
      }
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg {
      .navbar-nav {
        .nav-link {
          padding-left: 15px;
          padding-right: 15px;
          &.dropdown-toggle {
            padding-right: 27px;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    // Mobile navigation refinements
    #mainNav {
      padding: 0;
      .navbar-nav {
        flex-direction: row;
        flex-wrap: wrap;
        .nav-item {
          display: flex;
          flex-grow: 1;
          align-content: center;
          justify-content: center;
          .nav-link {
            padding: 10px 15px;
            &.dropdown-toggle {
              padding-right: 25px;
            }
          }
          &:last-of-type {
            padding-bottom: 0
          }
        }
      }

      .dropdown-menu {
        position: relative;

        &.show {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
        .dropdown-item {
          display: flex;
          flex-grow: 1;
          align-content: center;
          justify-content: center;
          padding: 10px 15px;
        }
        // display: flex;
        // flex-direction: column;
        // position: fixed;
        // height: 100vh;
        // width: 300px;
        // top: 0;
        // right: 0;
        // z-index: 101;
        // transform: translateX(300px);
        // transition: all 0.3s ease-in-out;
        // &.show {
        //   transform: translateX(0);
        //   transition: all 0.3s ease-in-out;
        // }
      }
    }
  }
}

.site-header {
  // background-color: $h-dark-blue;
  box-shadow: none;
  // border-bottom: 1px solid $darkblue;
  // .navbar {
  //   background-color: $h-dark-blue;
  // }
  #chooseLanguage {
    color: white;
    border-color: transparent;
    background-color: rgba($h-light-blue, 0.4);
  }
  @include transition(all 0.3s ease-in-out);
  &.headroom--not-top {
    background-color: $h-dark-blue;
    // border-bottom: 1px solid transparent;
    @include transition(all 0.3s ease-in-out);
    #chooseLanguage {
      color: $h-light-blue;
      border-color: $h-blue;
      background-color: transparent;
      &:hover,
      &:focus {
        color: white;
        background-color: $h-blue;
      }
    }
    &.slideUp {
      box-shadow: none;
      @include transition(all 0.3s ease-in-out);
    }
    &.slideDown {
      box-shadow: 0 1px 12px 1px rgba(0,0,0,0.2);
      @include transition(all 0.3s ease-in-out);
    }
  }
}

#home-header {
  position: relative;
  color: $text-color;
  background-color: rgba(white, 0.9);
  padding: 1.4rem 0 0;
  h1, h2, h3, h4, h5 {
    color: $teal;
  }
  #email-form .form-group.has-warning .form-control-feedback {
    color: $text-color;
  }
  .lead {
    margin-bottom: 12px;
  }
  .form-control,
  .custom-select {
    font-weight: 400;
    color: $text-color;
    border-color: $h-blue;
    border-radius: 0;
    background-color: #f3f3f3;
    height: 50px;
    padding: .375rem .75rem;
    // padding: 1rem;
  }
  .form-group {
    &:first-of-type {
      width: 15%;
      input {
        width: 100%;
      }
    }
    &:nth-of-type(2) {
      width: 20%;
      input {
        width: 100%;
      }
    }
    &:nth-of-type(3) {
      width: 15%;
      input {
        width: 100%;
      }
    }
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
  .custom-select {
    padding: .375rem 1.75rem .375rem .75rem;
    background: #f3f3f3 url('../img/select.svg') no-repeat right .75rem center;
    background-size: 8px 10px;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: lighten($text-color, 25%);
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: lighten($text-color, 25%);
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: lighten($text-color, 25%);
  }
  :-moz-placeholder { /* Firefox 18- */
    color: lighten($text-color, 25%);
  }
  .form-control-feedback {
    position: absolute;
    bottom: 0;
  }
  .select2-container--default {
    width: 100% !important;
  }
  .select2-container--default .select2-selection--single {
    height: 50px;
    background-color: #f3f3f3;
    border-color: $h-blue;
    border-radius: 0;
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: lighten($text-color, 25%);
    line-height: 50px;
    text-align: left;
    padding-left: .75rem;
    &:not([title^="Your Country"]) {
      color: $text-color;
    }
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50px;
  }
  .btn-primary {
    // background-color: #fff;
    height: 50px;
    letter-spacing: 4px;
    padding: 0px 40px;
    margin-left: 30px;
  }
  @media (max-width: 991px) {
    display: none;

    padding-left: 15px;
    padding-right: 15px;
    #email-form {
      .form-group {
        width: calc(100% / 3);
        margin: 0;
        padding-left: 4px;
        padding-right: 4px;
      }
      .submit_container {
        width: 100%;
        margin-top: 24px;
      }
    }
    .btn-primary {
      margin-left: 0;
    }
  }
  @media (max-width: 575px) {
    #email-form {
      .form-group {
        width: 100%;
        padding-bottom: 8px;
      }
      .submit_container {
        width: 100%;
        margin-top: 18px;
      }
    }
  }
}

#home-header-close {
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 1000;

  display: block;
  width: 70px;
  height: 70px;
  margin-left: auto;
  padding-top: 15px;
  padding-right: 16px;
  padding-left: 16px;
  float: none;
  clear: none;
  background-color: transparent;
  .x {
    position: static;
    top: 0px;
    right: 0px;
    display: inline-block;
    width: 41px;
    height: 1px;
    margin-left: -37px;
    float: right;
    background-color: #000;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    text-align: center;
  }
  .x2 {
    margin-left: 0px;
    float: right;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    text-align: left;
  }
}

.subscribe-bar {
  background-color: #041742;
  position: fixed;
  right: 15px;
  bottom: -35px;
  padding: 4px 25px 5px 4px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-size: 12px;
  img {
    height: 26px;
  }
  a {
    transition: none;
    color: white;
    &:hover {
      text-decoration: none;
    }
  }
  .btn-close {
    background-color: transparent;
    padding: 8px 2px;
    position: absolute;
    right: 4px;
    top: 8px;
    .x {
      width: 10px;
      height: 1px;
      float: right;
      margin-left: -10px;
      background-color: #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .x2 {
      margin-left: 0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

#mainNav,
.navbar-nav {
  align-items: center!important;
}

.dropdown-menu {
  background-color: $h-blue;
  border-radius: 0;
}
.dropdown-item {
  color: $h-light-blue;
  padding: 15px 60px;
  &:hover,
  &:focus {
    color: white;
    background-color: $blue;
  }
}
.dropdown-toggle {
  &::after {
    position: absolute;
    top: 20px;
    display: inline-block;
    // width: 12px;
    // height: 6.5px;
    // background-image: url('../img/icons/nav-arrow.png');
    // background-size: 12px;
    // background-repeat: no-repeat;
    // border: 0;
  }
}
#chooseLanguage {
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  color: $h-light-blue;
  border: 3px solid $h-blue;
  border-radius: 5rem;
  height: 45px;
  width: 45px;
  padding: .5rem 0 .5rem;
  margin-left: 14px;
  &::after {
    display: none;
  }
  &:hover,
  &:focus {
    color: white;
    background-color: $h-blue;
  }
}

.jumbotron {
  display: flex;
  flex-direction: column;
  align-items: center!important;
  position: relative;
  color: white;
  border-radius: 0;
  margin-bottom: 0;
  overflow: hidden;
  min-height: 100vh;
  padding: 4rem 0 0;
  text-align: center;
  text-shadow: 1px 1px 4px rgba(0,0,0,0.3);
  h1, h2, h3, h4, h5, h6 {
    color: white;
    margin-bottom: 10px;
  }
  h1 {
    font-size: 82px;
    line-height: 60px;
    font-weight: 700;
  }
  h2 {
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: none;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-size: 20px;
    line-height: 38px;
  }
  .btn {
    padding: 1rem;
    text-transform: none;
    letter-spacing: 2px;
    font-size: 0.9rem;
    border: 1px solid $h-light-blue;
    &:hover,
    &:focus {
      color: white;
      background-color: $h-light-blue;
    }
  }
  // .btn-ghost {
  //   margin: .5rem 0.5rem;
  // }
  @include bgimagecover($darkteal, '../vid/cities.jpg', center);
  #bgvid {
    animation-name: fadeIn;
    animation-fill-mode: both;
    animation-delay: 1.5s;
    animation-duration: 5s;

    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    @include bgimagecover($darkteal, '../vid/cities.jpg', center);
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .color-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: rgba(4, 20, 53, 0.7);
    background: linear-gradient(110deg, rgba($h-dark-blue, 0.6), rgba(#26db8d, 0.3)), rgba($h-dark-blue, 0.5);
    background-blend-mode: multiply;
  }
  .featured-announcement {
    border: 1px solid rgba(white, 0.5);
    padding: 3rem 2rem 1.6rem;
    margin-top: 3rem;
    .lead {
      font-weight: 100;
      font-size: 24px;
    }
    .text-link {
      color: $green;
      font-weight: 100;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
  .content-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-content: center;
  }
  .announcement-footer {
    flex-direction: row;
    justify-content: center;
    position: relative;
    right: auto;
    width: 100%;
    background-color: $h-dark-blue;
    padding: 2rem 15px;
    .btn {
      font-size: 1.2rem;
      font-weight: 300;
      padding: 1rem 3rem;
    }
    p {
      margin: 0;
      font-weight: 400;
    }
    .lead {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    span {
      display: inline-block;
      // margin-bottom: -6px;
    }
    .btn {
      letter-spacing: 2px;
      // margin-left: 2rem;
    }
  }
}

.social-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 25px;
  height: 100%;
  .social-icon {
    padding: 10px;
    border-radius: 5rem;
    background-color: $h-dark-blue;
    margin: .3rem 0;
    img {
      width: 30px;
    }
    i {
      color: white;
      font-size: 28px;
      line-height: 35px;
    }
    &:hover,
    &:focus {
      background-color: $h-blue;
    }
  }
}

.body-links {
  // padding: 0 84px;
  // margin-bottom: 6rem;
  .col {
    padding: 0 2px;
  }
}
.body-link {
  display: block;
  // background-color: $lightgray;
  // padding: 60px 0 30px;
  @include transition(all 0.3s ease-in-out);
  &:hover {
    text-decoration: none;
    // background-color: $green;
    @include transition(all 0.3s ease-in-out);
    h3 {
      &:before {
        width: 100px;
        @include transition(all 0.3s ease-in-out);
      }
    }
    // .feature-icon {
    //   transform-style: preserve-3d;
    //   transform: translateY(-5px);
    //   @include transition(all 0.3s ease-in-out);
    // }
    // .down-arrow {
    //   opacity: 1;
    //   @include transition(all 0.3s ease-in-out);
    // }
  }
  .feature-icon-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    background-color: white;
    box-shadow: inset 4px 4px 14px rgba(gray, 0.6);
    border-radius: 50%;
    margin: 0 auto;
  }
  .feature-icon {
    max-width: 100px;
    max-height: 100px;
    transform-style: preserve-3d;
    transform: translateY(0);
    @include transition(all 0.3s ease-in-out);
  }
  h3 {
    color: $darkblue;
    text-transform: none;
    letter-spacing: 2px;
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 1rem;
    transform: translateY(0);
    @include transition(all 0.3s ease-in-out);
    &:before {
      display: block;
      height: 3px;
      width: 50px;
      content: '';
      background-color: $h-light-blue;
      margin: 1.4rem auto 1rem;
      @include transition(all 0.3s ease-in-out);
    }
  }
  .down-arrow {
    width: 19px;
    opacity: 0;
    @include transition(all 0.3s ease-in-out);
  }
}

@keyframes wobble {
  0%, 100% {transform: translate(0px, 0px);}
  16.6667% {transform: translate(4px, 4px);}
  33.3334% {transform: translate(-4px, -4px);}
  50% {transform: translate(2px, 2px);}
  66.6668% {transform: translate(6px, -6px);}
  83.3335% {transform: translate(3px, 3px);}
}

.about-zen {
  background-image: url('../img/gray-dots.jpg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
  padding: 4rem 15px;
  // margin-bottom: 6rem;
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.zentext {
  .logo-text {
    text-transform: none;
    letter-spacing: 0;
  }
  img {
    max-width: 200px;
    margin: 0 auto;
  }
  h3 {
    color: $blue;
    font-size: 20px;
    letter-spacing: 5px;
    margin-bottom: 5px;
  }
  .zenlink {
    color: $gray;
    display: block;
    position: relative;
    height: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 5rem 0;
    @media (max-width: 767px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    p {
      position: absolute;
      width: 100%;
    }
    &:hover {
      text-decoration: none;
      background-color: rgba(27, 47, 89, .05);
    }
  }
}

.about-zen-interior {
  padding-bottom: 8rem;
  &.full-width {
    padding-bottom: 0;
    div[class^="col-"] {
      padding: 1px;
    }
  }
  .col {
    padding: 0 1px;
  }
  .zentext {
    .zenlink {
      background-color: rgba(27, 47, 89, .05);
      max-width: none;
      &:hover {
        background-color: $green;
      }
    }
  }
}

.wobble {
  animation-name: wobble;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
}

.zen-icon {
  width: 400px;
  max-width: 100%;
  // opacity: 0.2;
}

#about {
  padding: 3rem 0;
  @include bgimagecover(white, '../img/bkg/wavy.jpg', center);
  .container-fluid {
    max-width: 1800px;
  }
  .logo-text {
    font-size: 4.7rem;
    letter-spacing: -3px;
    margin-bottom: 0;
    line-height: 1;
    @media (max-width: 575px) {
      font-size: 3.7rem;
    }
  }
  .lead.section-title {
    color: $darkblue;
    font-size: 2rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: 0;
    line-height: 1.2;
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 2.2rem;
    // padding: 4rem 0;
  }
  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  .zencash-video {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .icon-row {
    margin-top: 4rem;
  }
}

#overview {
  @include bgimagecover($h-dark-blue, '../img/bkg/ripples.jpg', left bottom);
  padding: 5rem 0;
  h2 {
    color: white;
    letter-spacing: 4px;
  }
  h3, h4 {
    color: white;
    letter-spacing: 2px;
    font-weight: 100;
    text-transform: none;
  }
  h3 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 0.1rem;
  }
}

#overview-graphic {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin-top: 3rem;
  @media (max-width: 767px) {
    margin-top: 1rem;
  }
  .overview-item {
    flex: 0 0 25%;
    max-width: 25%;
    text-align: center;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    margin-top: 3rem;
    &:nth-of-type(-n+4) {
      margin-bottom: 3rem;
      margin-top: 0;
    }

    @media (max-width: 767px) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;

      margin-top: 15px;
      margin-bottom: 15px;
      &:nth-of-type(-n+4) {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
    @media (max-width: 575px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    // @media (min-width: 1200px) {
    //   padding: 0 4rem;
    // }
    // @media (min-width: 1500px) {
    //   padding: 0 8rem;
    // }
    &:nth-of-type(1) {
      .overview-image-border {
        background-image: url('../img/icons/overview/overview-circles-01.png');
      }
    }
    &:nth-of-type(2) {
      .overview-image-border {
        background-image: url('../img/icons/overview/overview-circles-02.png');
      }
    }
    &:nth-of-type(3) {
      .overview-image-border {
        background-image: url('../img/icons/overview/overview-circles-03.png');
      }
    }
    &:nth-of-type(4) {
      .overview-image-border {
        background-image: url('../img/icons/overview/overview-circles-04.png');
      }
    }
    &:nth-of-type(5) {
      .overview-image-border {
        background-image: url('../img/icons/overview/overview-circles-05.png');
      }
    }
    &:nth-of-type(6) {
      .overview-image-border {
        background-image: url('../img/icons/overview/overview-circles-06.png');
      }
    }
    &:nth-of-type(7) {
      .overview-image-border {
        background-image: url('../img/icons/overview/overview-circles-07.png');
      }
    }
  }
  .overview-image {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 0.6rem;
    img {
      display: block;
      height: 115px;
      width: 115px;
      // margin-right: -8px;
    }
  }
  .overview-image-border {
    position: absolute;
    display: block;
    content: '';
    height: 115px;
    width: 115px;
    background-size: contain;
  }
  .timeline-nodes {
    position: absolute;
    top: 50%;
    height: 6rem;
    width: calc(100% - 25% / 2);
    // max-width: 83.3%;
    margin-top: -2.0rem;
    margin-left: 0.5px;
    // padding: 0 6rem;
    @media (max-width: 767px) {
      display: none;
    }
    @media (min-width: 1024px) {
      margin-top: -2.0rem;
    }
    @media (min-width: 1475px) {
      margin-top: -2.0rem;
    }
    .node {
      position: relative;
      height: 50px;
      flex: 0 0 calc(100% / 7);
      max-width: 20%;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        height: 50%;
        width: 2px;
        background-color: $h-light-blue;
        margin-left: -1px;
      }
      &:nth-of-type(even) {
        &:before {
          top: 50%;
          margin-top: -1px;
        }
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        background-color: $h-light-blue;
        margin-top: -1px;
      }
      &:first-of-type {
        &:after {
          right: 0;
          width: 50%;
        }
      }
      &:last-of-type {
        &:after {
          width: 50%;
        }
      }
      // span {
      //   display: block;
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   height: 20px;
      //   width: 20px;
      //   background-color: #dcdcdc;
      //   border-radius: 5rem;
      //   margin-top: -10px;
      //   margin-left: -10px;
      // }
    }
  }
}

#partners-and-investors {
  padding: 5rem 0;
  h2 {
    color: $h-dark-blue;
    letter-spacing: 2px;
    margin-bottom: 2rem;
  }
  img {
    opacity: 0.70;
  }
}

#features {
  overflow: hidden;
  // color: white;
  background-color: white;
  background-image: url("../img/bkg/gray-nodes.jpg");
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  padding: 4rem 0 3rem;
  h2 {
    color: $h-dark-blue;
    font-size: 1.4rem;
    letter-spacing: 4px;
    font-weight: 600;
  }
  h3 {
    color: $h-dark-blue;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: 0;
    max-width: 500px;
    &:after {
      display: block;
      content: '';
      height: 2px;
      width: 100px;
      background-color: $h-light-blue;
      margin-top: 1rem;
    }
  }
  .btn-white {
    color: $h-dark-blue;
    text-transform: none;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.8rem 2.4rem;
    margin-top: 1rem;
    border: 1px solid $h-light-blue;
    &:not(:last-of-type) {
      margin-right: 0.8rem;
    }
    &:hover,
    &:focus {
      color: white;
      background-color: $h-light-blue;
      box-shadow: none;
    }
  }
  .feature-row {
    padding: 2.4rem 0;
    .icon {
      display: flex;
      align-items: center;
    }
    img {
      display: block;
      width: 400px;
      max-width: 100%;
      margin: 0 auto;
    }
    &.odd {
      .content {
        padding-left: 45px;
      }
    }
    &.even {
      text-align: right;
      .content {
        padding-right: 45px;
      }
    }
  }
  .content {
    p {
      font-size: 1.4rem;
      strong {
        font-weight: 700;
      }
    }
    ul, ol {
      margin-bottom: 0;
    }
  }
}

section#features {
  ul {
    li {
      font-weight: 400;
      font-size: 1.2rem;
      padding: .6rem 0;
      max-width: 480px;
    }
  }
}

#tech {
  color: white;
  background-color: #1b2f59;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url("../img/nodes-bkg.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  padding: 160px 50px 0;
  .container-fluid {
    max-width: 1800px;
    &.full-width {
      max-width: none;
    }
  }
  .subhead-large {
    color: $green;
  }
  h3 {
    color: $green;
    font-weight: 700;
    text-transform: none;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0;
  }
  ul {
    li {
      margin-left: 0 !important;
      &::before {
        display: none;
      }
    }
  }
  a:not(.btn) {
    color: $green;
    text-decoration: underline;
  }
  .technology-cards {
    background-color: #041435;
    background-image: url('../img/connected-lines-horizontal.png');
    background-position: 50% 0px;
    background-size: auto;
    background-repeat: no-repeat;
    margin-left: -65px;
    margin-right: -65px;
    .col,
    > div[class^="col"] {
      padding: 120px 5%;
      & > .row {
        height: 100%;
        div[class^="col"] {
          display: flex;
          flex-direction: column;
          padding: 15px 4%;
        }
        .content {
          flex-grow: 1;
        }
      }
      @media (max-width: 575px) {
        padding: 20px 15px;
      }
    }
    .icon {
      background-color: $green;
      height: 100px;
      width: 100px;
      margin: 0 auto;
      border-radius: 5rem;
      padding: 15px 0;
      text-align: center;
      img {
        height: 70px;
        width: auto;
      }
    }
    h3 {
      color: $green;
      text-transform: uppercase;
      letter-spacing: 6px;
      font-size: 24px;
    }
    p {
      font-weight: 300;
      line-height: 1.8;
      font-size: 22px;
    }
    .btn-inverse {
      color: $green;
      border-color: $green;
      margin-top: 1.4rem;
      &:hover,
      &:focus {
        color: $darkblue;
      }
    }
  }
  .stats-section {
    background-color: #041435;
    margin-left: -65px;
    margin-right: -65px;
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .circle-dots {
      margin-top: 130px;
      margin-bottom: 30px;
      h2 {
        margin-bottom: 0;
        font-size: 24px;
        padding-left: 8px;
      }
      img {
        width: 250px;
        will-change: transform;
        margin-top: -142px;
      }
    }
    .stats {
      text-align: center;
      padding: 3rem 0 6rem;
      .stats-headline {
        color: white;
        font-size: 1.2rem;
        line-height: 1.4;
        letter-spacing: 5px;
        text-transform: uppercase;
        // Keep words on separate lines
        word-spacing: 9999999px;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid white;
      }
      .stats-data {
        color: $green;
        font-size: 3rem;
        font-weight: 100;
        span {
          display: block;
        }
        .subtext {
          font-size: 1.2rem;
          font-weight: 400;
        }
      }
    }
  }
}
section#tech {
  ul {
    li {
      font-weight: 300;
      font-size: 1.2rem;
      &:only-of-type {
        margin-left: 0;
        &:before {
          display: none;
        }
      }
    }
  }
}



#roadmap {
  .logo-text {
    text-transform: uppercase;
    max-width: 100%;
  }
  .subscribe {
    img {
      max-width: 80px;
    }
    h3 {
      color: #1B2F5A;
      text-transform: none;
      letter-spacing: 0;
      font-weight: 600;
    }
  }
  .roadmap {
    padding: 60px 50px 0;
    .roadmap-item {
      position: relative;
      height: 400px;
      &.roadmap-line {
        margin-top: -220px;
        .road {
          position: absolute;
          top: 50%;
          left: 200px;
          right: 200px;
          height: 20px;
          background-color: #9cf7ff;
          z-index: -1;
        }
      }
      &.roadmap-circle-right {
        margin-top: -200px;
        .road {
          position: absolute;
          z-index: -1;
          width: 200px;
          height: 400px;
          right: 0;
          border-top-right-radius: 200px;
          border-bottom-right-radius: 200px;
          background-color: #9cf7ff;
          &:before {
            content: '';
            z-index: -1;
            position: absolute;
            display: block;
            background-color: #fdfdfd;
            width: 180px;
            height: 360px;
            top: 20px;
            border-top-right-radius: 200px;
            border-bottom-right-radius: 200px;
          }
        }
      }
      &.roadmap-circle-left {
        margin-top: -200px;
        .road {
          position: absolute;
          z-index: -1;
          width: 200px;
          height: 400px;
          border-top-left-radius: 200px;
          border-bottom-left-radius: 200px;
          background-color: #9cf7ff;
          &:before {
            content: '';
            position: absolute;
            z-index: -1;
            width: 180px;
            height: 360px;
            top: 20px;
            right: 0;
            border-top-left-radius: 200px;
            border-bottom-left-radius: 200px;
            background-color: #fdfdfd;
          }
        }
      }
      .road {
        .arrow-top {
          position: absolute;
          right: -25px;
          top: -9px;
          width: 0;
          height: 0;
          border-left: 50px solid transparent;
          border-right: 50px solid transparent;
          border-top: 30px solid #97ffd3;
          transform: rotate(20deg);
        }
        .arrow-bottom {
          position: absolute;
          right: -26px;
          top: -3px;
          width: 0;
          height: 0;
          border-left: 50px solid transparent;
          border-right: 50px solid transparent;
          border-bottom: 30px solid #97ffd3;
          transform: rotate(-20deg);
        }
      }
      .item-img {
        position: absolute;
      }
      .year-number {
        position: absolute;
        top: 50%;
        margin-top: -50px;
        color: #e0e0e0;
        font-size: 2rem;
        font-weight: bold;
        font-style: italic;
      }
      .item-info {
        position: absolute;
        .quarter {
          position: absolute;
          display: inline-block;
          padding: 10px;
          background-image: linear-gradient(#afedfe, #97ffd3);
          border-radius: 50%;
          width: 55px;
          height: 55px;
          &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            background-color: #9cf7ff;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            height: 6px;
            width: 6px;
            background-color: #9cf7ff;
            border-radius: 50%;
          }
          span {
            background-color: #26db8d;
            border-radius: 50%;
            width: 100%;
            line-height: 35px;
            display: inline-block;
            text-align: center;
            font-weight: bold;
            font-size: 1.2rem;
            color: white;
          }
        }
        &.info-bottom {
          .quarter {
            &:before {
              left: 50%;
              top: 50%;
              width: 2px;
              height: 60px;
            }
            &:after {
              top: 85px;
              left: 50%;
              margin-left: -2px;
            }
          }
          .item-detail {
            top: 95px;
            left: 20px;
            width: 350px;
          }
        }
        &.info-top {
          .quarter {
            bottom: 0;
            &:before {
              left: 50%;
              bottom: 50%;
              width: 2px;
              height: 60px;
            }
            &:after {
              bottom: 85px;
              left: 50%;
              margin-left: -2px;
            }
          }
          .item-detail {
            bottom: 95px;
            left: 25px;
            width: 350px;
          }
        }
        &.info-left {
          .quarter {
            &:before {
              top: 50%;
              right: 50%;
              width: 60px;
              height: 2px;
            }
            &:after {
              top: 50%;
              right: 85px;
              margin-top: -2px;
            }
          }
          .item-detail {
            right: 100px;
            top: -25px;
            width: 266px;
          }
        }
        &.info-right {
          .quarter {
            &:before {
              top: 50%;
              left: 50%;
              width: 60px;
              height: 2px;
            }
            &:after {
              top: 50%;
              margin-top: -2px;
              left: 85px;
            }
          }
          .item-detail {
            left: 100px;
            top: 10px;
            width: 266px;
          }
        }

        .item-detail {
          position: absolute;
          z-index: 1;
          min-width: 300px;
          h4 {
            color: $text-color;
            text-transform: none;
            font-weight: 700;
            margin-bottom: 0;
            span {
              font-weight: normal;
              font-style: italic;
            }
            a {
              color: $text-color;
              &:hover {
                text-decoration: none;
                color: $brand-color
              }
            }
          }
          ul {
            margin-bottom: 0;
            li {
              padding: 0 !important;
              font-size: 1rem !important;
              a {
                color: $text-color;
                &:hover {
                  text-decoration: none;
                  color: $brand-color
                }
              }
            }
          }
        }
      }
    }
  }
  #roadmap-lg {
    padding: 60px 50px 0;
    .y2017q2 {
      margin-top: 0;
      .road {
        background-image: -webkit-linear-gradient(#b0ecff, #b0ecff);
        background-image: linear-gradient(#b0ecff, #b0ecff);
      }
      .year-number {
        left: 50%;
      }
      .item-img {
        max-width: 300px;
        top: 30px;
      }
      .item-info {
        top: 50%;
        margin-top: -20px;
        left: 310px;
      }
    }
    .y2017q3 {
      .road {
        background-image: -webkit-linear-gradient(#b0ecff, #acf0f8);
        background-image: linear-gradient(#b0ecff, #acf0f8);
      }
      .item-img {
        right: 80px;
        top: -270px;
        max-width: 180px;
      }
      .item-info {
        top: 50%;
        margin-top: -20px;
        right: -14px;
        .quarter {
          right: 0;
        }
      }
    }
    .y2017q4 {
      .road {
        background-image: -webkit-linear-gradient(#aceff8, #acf0f8);
        background-image: linear-gradient(#aceff8, #acf0f8);
      }
      .item-img {
        left: 50%;
        margin-left: -220px;
        max-width: 185px;
        top: 30px;
      }
      .item-info {
        left: 50%;
        top: 50%;
        margin-top: -15px;
      }
    }
    .y2018q1 {
      .road {
        background-image: -webkit-linear-gradient(#aceff8, #a7f2f0);
        background-image: linear-gradient(#aceff8, #a7f2f0);
      }
      .item-img {
        bottom: 30%;
        left: -140px;
        max-width: 220px;
      }
      .item-info {
        bottom: -15px;
        left: 160px;
      }
    }
    .y2018q1-q2 {
      .road {
        background-image: -webkit-linear-gradient(#a8f3f0, #a7f2f0);
        background-image: linear-gradient(#a8f3f0, #a7f2f0);
      }
      .year-number {
        left: 35%;
      }
    }
    .y2018q2 {
      .road {
        background-image: -webkit-linear-gradient(#a8f3f0, #a3f5e8);
        background-image: linear-gradient(#a8f3f0, #a3f5e8);
      }
      .item-img {
        max-width: 350px;
        top: -200px;
        right: -50px;
      }
      .item-info {
        top: 50%;
        margin-top: -20px;
        right: -14px;
        .quarter {
          right: 0;
        }
      }
    }
    .y2018q3 {
      .road {
        background-image: -webkit-linear-gradient(#a4f6e9, #a3f5e8);
        background-image: linear-gradient(#a4f6e9, #a3f5e8);
      }
      .item-img {
        left: 40px;
        bottom: 50%;
        margin-bottom: -85px;
        max-width: 230px;
      }
      .item-info {
        bottom: 50%;
        margin-bottom: -35px;
        left: 250px;
      }
    }
    .y2018q4 {
      .road {
        background-image: -webkit-linear-gradient(#a4f6e9, #9ff9e1);
        background-image: linear-gradient(#a4f6e9, #9ff9e1);
      }
      .item-img {
        bottom: -85px;
        left: -130px;
        max-width: 250px;
      }
      .item-info {
        top: 50%;
        margin-top: -80px;
        left: -8px;
      }
    }
    .y2019q1 {
      .road {
        background-image: -webkit-linear-gradient(#9ff8e1, #9ff9e1);
        background-image: linear-gradient(#9ff8e1, #9ff9e1);
      }
      .item-img {
        right: -125px;
        top: -40px;
        max-width: 300px;
      }
      .item-info {
        top: 50%;
        left: 50%;
        margin-top: 35px;
      }
      .year-number {
        right: 25%;
      }
    }
    .y2019q2 {
      .road {
        background-image: -webkit-linear-gradient(#9ff8e1, #9bfbd9);
        background-image: linear-gradient(#9ff8e1, #9bfbd9);
      }
      .item-img {
        max-width: 135px;
        bottom: -10px;
        right: -55px;
      }
      .item-info {
        bottom: 0;
        margin-bottom: -15px;
        left: 60%;
      }
    }
    .y2019q3 {
      .road {
        background-image: -webkit-linear-gradient(#9cfcda, #9bfbd9);
        background-image: linear-gradient(#9cfcda, #9bfbd9);
      }
      .item-img {
        left: 110px;
        max-width: 80px;
      }
      .item-info {
        left: 200px;
        bottom: 50%;
        margin-bottom: -35px;
      }
    }
    .y2019q4 {
      .road {
        background-image: -webkit-linear-gradient(#9cfcda, #97ffd2);
        background-image: linear-gradient(#9cfcda, #97ffd2);
      }
      .item-img {
        bottom: -45px;
        left: -175px;
        max-width: 400px;
      }
      .item-info {
        bottom: 0;
        left: 30%;
        margin-bottom: -15px;
      }
    }
    .y2020 {
      .road {
        background-image: -webkit-linear-gradient(#98ffd3, #97ffd2);
        background-image: linear-gradient(#97ffd3, #97ffd2);
        right: 20px;
      }
      .year-number {
        left: 50%;
      }
      .item-img {
        right: 90px;
        bottom: 50%;
        max-width: 240px;
      }
    }
  }
  #roadmap-md {
    padding: 150px 0 0;
    .roadmap-item {
      height: 300px;
      &.roadmap-line {
        margin-top: -165px;
        .road {
          height: 15px;
          left: 150px;
          right: 150px;
        }
      }
      &.roadmap-circle-right {
        margin-top: -150px;
        .road {
          width: 150px;
          height: 300px;
          border-top-right-radius: 150px;
          border-bottom-right-radius: 150px;
          &:before {
            width: 135px;
            height: 270px;
            top: 15px;
            border-top-right-radius: 135px;
            border-bottom-right-radius: 135px;
          }
        }
      }
      &.roadmap-circle-left {
        margin-top: -150px;
        .road {
          width: 150px;
          height: 300px;
          border-top-left-radius: 150px;
          border-bottom-left-radius: 150px;
          &:before {
            width: 135px;
            height: 270px;
            top: 15px;
            border-top-left-radius: 135px;
            border-bottom-left-radius: 135px;
          }
        }
      }
      .item-img {
        max-width: 150px;
      }
      .item-info {
        &.info-left {
          .item-detail {
            right: 35px;
            top: 15px;
          }
        }
        .item-detail {
          min-width: 200px;
          max-width: 250px;
          h4 {
            font-size: 1.1rem;
          }
          ul {
            li {
              font-size: 0.9rem !important;
            }
          }
        }
      }
      .year-number {
        left: 50%;
        top: 50%;
        margin-top: -40px;
        margin-left: -30px;
      }
    }

    .y2017q2-p1 {
      margin-top: 0;
      .road {
        background-image: -webkit-linear-gradient(#b0ecff, #b0ecff);
        background-image: linear-gradient(#b0ecff, #b0ecff);
      }
      .item-img {
        max-width: 200px;
        top: 50%;
        margin-top: -115px;
      }
    }
    .y2017q2-p2 {
      .road {
        background-image: -webkit-linear-gradient(#b0ecff, #adedfb);
        background-image: linear-gradient(#b0ecff, #adedfb);
      }
      .item-info {
        right: 40px;
        top: 95px;
        .item-detail {
          top: 0;
          width: 220px;
        }
      }
    }
    .y2017q3-p1 {
      .road {
        background-image: -webkit-linear-gradient(#adedfb, #adedfb);
        background-image: linear-gradient(#adedfb, #adedfb);
      }
      .item-img {
        max-width: 100px;
        left: -5px;
      }
    }
    .y2017q3-p2 {
      .road {
        background-image: -webkit-linear-gradient(#adedfb, #aceff7);
        background-image: linear-gradient(#adedfb, #aceff7);
      }
      .item-info {
        top: 90px;
        left: -16px;
      }
    }
    .y2017q4-p1 {
      .road {
        background-image: -webkit-linear-gradient(#aceff7, #aceff7);
        background-image: linear-gradient(#aceff7, #aceff7);
      }
      .item-img {
        max-width: 120px;
        right: 20px;
        top: 85px;
      }
    }
    .y2017q4-p2 {
      .road {
        background-image: -webkit-linear-gradient(#aceff7, #aaf1f4);
        background-image: linear-gradient(#aceff7, #aaf1f4);
      }
      .item-info {
        right: 42px;
        top: 90px;
        .item-detail {
          width: 225px;
        }
      }
    }
    .y2018q1-p1 {
      .road {
        background-image: -webkit-linear-gradient(#aaf1f4, #aaf1f4);
        background-image: linear-gradient(#aaf1f4, #aaf1f4);
      }
      .item-img {
        max-width: 120px;
        top: 295px;
        left: -25px;
      }
    }
    .y2018q1-p2 {
      .road {
        background-image: -webkit-linear-gradient(#aaf1f4, #a8f3f0);
        background-image: linear-gradient(#aaf1f4, #a8f3f0);
      }
      .item-info {
        top: 75px;
        left: -12px;
      }
    }
    .y2018q2-p1 {
      .road {
        background-image: -webkit-linear-gradient(#a8f3f0, #a8f3f0);
        background-image: linear-gradient(#a8f3f0, #a8f3f0);
      }
      .item-img {
        right: 0;
        top: 80px;
      }
    }
    .y2018q2-p2 {
      .road {
        background-image: -webkit-linear-gradient(#a8f3f0, #a6f4ed);
        background-image: linear-gradient(#a8f3f0, #a6f4ed);
      }
      .item-info {
        right: 55px;
        top: 55px;
      }
    }
    .y2018q3-p1 {
      .road {
        background-image: -webkit-linear-gradient(#a6f4ed, #a6f4ed);
        background-image: linear-gradient(#a6f4ed, #a6f4ed);
      }
      .item-img {
        top: 60px;
        left: 20px;
        max-width: 120px;
      }
    }
    .y2018q3-p2 {
      .road {
        background-image: -webkit-linear-gradient(#a6f4ed, #a3f5e8);
        background-image: linear-gradient(#a6f4ed, #a3f5e8);
      }
      .item-info {
        top: 50px;
        left: -2px;
      }
    }
    .y2018q4-p1 {
      .road {
        background-image: -webkit-linear-gradient(#a3f5e8, #a3f5e8);
        background-image: linear-gradient(#a3f5e8, #a3f5e8);
      }
      .item-img {
        right: 0;
        top: 125px;
        max-width: 120px;
      }
    }
    .y2018q4-p2 {
      .road {
        background-image: -webkit-linear-gradient(#a3f5e8, #a1f7e4);
        background-image: linear-gradient(#a3f5e8, #a1f7e4);
      }
      .item-info {
        right: 41px;
        top: 90px;
      }
    }
    .y2019q1-p1 {
      .road {
        background-image: -webkit-linear-gradient(#a1f7e4, #a1f7e4);
        background-image: linear-gradient(#a1f7e4, #a1f7e4);
      }
      .item-img {
        max-width: 130px;
        top: 295px;
        left: -10px;
      }
    }
    .y2019q1-p2 {
      .road {
        background-image: -webkit-linear-gradient(#a1f7e4, #a0f9e1);
        background-image: linear-gradient(#a1f7e4, #a0f9e1);
      }
      .item-info {
        top: 75px;
        left: -12px;
      }
    }
    .y2019q2-p1 {
      .road {
        background-image: -webkit-linear-gradient(#a0f9e1, #a0f9e1);
        background-image: linear-gradient(#a0f9e1, #a0f9e1);
      }
      .item-img {
        right: 20px;
        top: 88px;
        max-width: 80px;
      }
    }
    .y2019q2-p2 {
      .road {
        background-image: -webkit-linear-gradient(#a0f9e1, #9efbde);
        background-image: linear-gradient(#a0f9e1, #9efbde);
      }
      .item-info {
        right: 55px;
        top: 55px;
      }
    }
    .y2019q3-p1 {
      .road {
        background-image: -webkit-linear-gradient(#9efbde, #9efbde);
        background-image: linear-gradient(#9efbde, #9efbde);
      }
      .item-img {
        top: 260px;
        left: 20px;
        max-width: 60px;
      }
    }
    .y2019q3-p2 {
      .road {
        background-image: -webkit-linear-gradient(#9efbde, #9cfcda);
        background-image: linear-gradient(#9efbde, #9cfcda);
      }
      .item-info {
        top: 50px;
        left: -2px;
      }
    }
    .y2019q4-p1 {
      .road {
        background-image: -webkit-linear-gradient(#9cfcda, #9cfcda);
        background-image: linear-gradient(#9cfcda, #9cfcda);
      }
      .item-img {
        right: 0;
        top: 125px;
      }
    }
    .y2019q4-p2 {
      .road {
        background-image: -webkit-linear-gradient(#9cfcda, #99fed6);
        background-image: linear-gradient(#9cfcda, #99fed6);
      }
      .item-info {
        right: 41px;
        top: 90px;
      }
    }
    .y2020-p1 {
      .road {
        background-image: -webkit-linear-gradient(#99fed6, #99fed6);
        background-image: linear-gradient(#99fed6, #99fed6);
      }
    }
    .y2020-p2 {
      .road {
        background-image: -webkit-linear-gradient(#99fed6, #97ffd2);
        background-image: linear-gradient(#99fed6, #97ffd2);
      }
    }
    .y2020-p3 {
      &.roadmap-item {
        .road {
          right: 25px;
        }
      }
      .road {
        background-image: -webkit-linear-gradient(#97ffd2, #97ffd2);
        background-image: linear-gradient(#97ffd2, #97ffd2);
      }
      .item-img {
        top: -70px;
        left: 30px;
      }
      .year-number {
        margin-left: 0;
      }
    }
  }
}

#roadmap:not(body) {
  overflow-x: hidden;
  padding: 100px 0 0;
  .zencash-logo {
    max-width: 338px;
  }
  .year-title {
    position: relative;
    color: white;
    margin: 3rem 0;
    span {
      display: inline-block;
      font-size: 36px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 10px;
      background-color: $darkblue;
      border-radius: 5rem;
      padding: 30px 50px 30px 60px;
      &:before,
      &:after {
        position: absolute;
        left: 50%;
        margin-left: -10px;
        display: block;
        content: '';
        height: 20px;
        width: 20px;
        background-color: $green;
        border-radius: 5rem;
      }
      &:before {
        top: -14px;
      }
      &:after {
        bottom: -14px;
      }
    }
    .vertical-line {
      display: block;
      width: 1px;
      height: 40px;
      background-color: $darkblue;
      margin: 0 auto;
    }
  }
  .entry-icon {
    display: flex;
    align-items: center;
    width: 30%;
    position: relative;
    // padding-top: 12px;
    .icon-container {
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 150px;
      background-color: $green;
      border-radius: 5rem;
      img {
        max-width: 50%;
        max-height: 50%;
      }
    }
    .horizontal-line {
      position: absolute;
      top: 50%;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $darkblue;
    }
  }
  .entry-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    h3 {
      color: #1b2f59;
      font-size: 48px;
      text-transform: none;
      line-height: 70px;
      font-weight: 100;
      letter-spacing: 0;
      margin-bottom: 0;
    }
  }

  .timeline-entry {
    display: flex;
    width: 70%;
    margin: 3rem 0;
    &.odd {
      margin-left: -15px;
      .icon-container {
        margin-left: auto;
      }
      .entry-text {
        padding-left: 3rem;
      }
    }
    &.even {
      margin-left: auto;
      margin-right: -15px;
      .entry-icon {
        order: 1;
      }
      .icon-container {
        margin-right: auto;
      }
      .entry-text {
        order: 0;
        padding-right: 3rem;
        text-align: right;
      }
    }
  }

}

.btn {
  .download-arrow {
    width: 17px;
  }
}

#white-paper {
  @include bgimagecover($h-dark-blue, '../img/bkg/wavy-nodes.jpg', center);
  padding: 2rem 0 3rem;
  h2 {
    color: white;
    font-size: 2.5rem;
    // &:after {
    //   display: block;
    //   content: '';
    //   height: 3px;
    //   width: 100px;
    //   background-color: $h-light-blue;
    //   margin: 1rem auto 0;
    // }
  }
  .col {
    padding: 0 5px;
  }
  .container {
    // padding: 5rem 15px 0;
    // border-top: 1px solid $gray;
    // border-bottom: 1px solid $gray;
  }
  .feature-icon {
    width: 105px;
    margin-bottom: 1rem;
  }
}

#resources {
  background-color: darken(#f8f8f8, 1%);
  background-image: url("../img/crazy-lines.png");
  background-position: 0px 0px;
  background-size: 960px;
  padding: 8rem 0;
  .logo-text {
    font-size: 6rem;
    letter-spacing: -5px;
    @media (max-width: 767px) {
      font-size: 4rem;
    }
  }
  .subhead-image {
    max-width: 520px;
    margin-bottom: 2rem;
  }
  h2.subhead {
    color: $darkblue;
    font-size: 42px;
    line-height: 1.4;
    font-weight: 100;
    letter-spacing: 0;
    text-transform: none;
  }
  .card {
    h3 {
      color: $darkblue;
      font-size: 1.2rem;
      letter-spacing: 4px;
      line-height: 1.4;
    }
    p {
      font-weight: 300;
    }
    .btn {
      margin-top: 1rem;
    }
    .btn-lg {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .inline-buttons {
      .btn {
        margin-left: 6px;
        margin-right: 6px;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
  .card-body {
    justify-content: flex-start;
    padding: 3rem 1.5rem 2.25rem;
  }
}

#media-mentions {
  background-color: $darkblue;
  padding: 4rem 0;
  .lined-subtext {
    color: $green;
    width: 100%;
    &::before,
    &::after {
      background-color: $green;
    }
  }
}

#blog {
  padding: 5rem 15px;
  background-color: #eee;
  #blogPosts {
    max-width: 1140px;
    margin: 0 auto;
  }
  .container {
    max-width: 1140px;
  }
  h2 {
    color: $h-dark-blue;
    font-size: 2rem;
    letter-spacing: 2px;
  }
  .card {
    &:hover,
    &:focus {
      .card-img-overlay {
        height: 100%;
        @include transition(all 0.3s ease-in-out);
      }
      h5.category {
        height: auto;
        visibility: visible;
        opacity: 1;
        @include transition(all 0.3s ease-in-out);
      }
      .card-title {
        height: auto;
        visibility: visible;
        opacity: 1;
        margin-bottom: 0.5rem;
        @include transition(all 0.3s ease-in-out);
      }
      .blog-arrow {
        height: auto;
        visibility: visible;
        opacity: 1;
        @include transition(all 0.3s ease-in-out);
      }
    }
  }
  .card-img {
    border-radius: 0;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .card-img-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: auto;
    height: 50px;
    color: white;
    background-color: rgba($h-dark-blue, 0.9);
    border-radius: 0;
    padding: 1rem 1.4rem;
    @include transition(all 0.3s ease-in-out);
    h5.category {
      height: 0;
      visibility: hidden;
      opacity: 0;
      @include transition(all 0.3s ease-in-out);
      color: $h-light-blue;
      margin-bottom: 0.2rem;
    }
    .card-title {
      height: 0;
      visibility: hidden;
      opacity: 0;
      @include transition(all 0.3s ease-in-out);
      max-width: 400px;
      line-height: 1.1;
      margin-bottom: 0;
      a {
        border-bottom: 2px solid transparent;
        &:hover,
        &:focus {
          border-color: $h-light-blue;
        }
      }
    }
    a {
      color: white;
      text-transform: none;
      font-size: 1.2rem;
    }
    .date {
      // margin-top: .75rem;
      margin-bottom: 0;
    }
    .blog-arrow {
      height: 0;
      visibility: hidden;
      opacity: 0;
      @include transition(all 0.3s ease-in-out);
    }
  }
  .overlay-link {
    display: block;
  }
  .col-lg-4 {
    padding: 1.25rem;
  }
  .btn-ghost {
    color: $h-dark-blue;
    text-transform: none;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 1rem;
    background-color: white;
    border-color: $h-light-blue;
    padding: 1rem 3rem;
    &:hover,
    &:focus {
      color: white;
      background-color: $h-light-blue;
    }
  }

  .card-img-top {
    border-radius: 0;
    &.hidden {
      display: none;
    }
  }
  .card {
    text-align: left;
    // margin-bottom: 40px;
  }
  .card-body {
    align-items: flex-start;
    padding: 20px;
    .category {
      color: $text-color;
      font-size: 1rem;
      letter-spacing: 3px;
      margin-bottom: .3rem;
      font-weight: 300;
    }
    .card-title {
      text-transform: none;
      font-weight: 600;
      a {
        color: $darkblue;
        &:hover,
        &:focus {
          color: #185e94;
          text-decoration: underline;
        }
      }
    }
    .date {
      margin-bottom: 0;
    }
  }
}

#use-zen {
  background-color: #041435;
  background-image: linear-gradient(180deg, rgba(4, 20, 53, .5), rgba(4, 20, 53, .5)), url('../img/use-zen.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-attachment: scroll, fixed;
  padding-top: 120px;
  padding-bottom: 120px;
  .container-fluid {
    max-width: 1800px;
  }
  .subhead-large {
    color: $green;
  }
  // .section-title {
  //   color: white;
  //   font-size: 60px;
  //   line-height: 70px;
  //   font-weight: 100;
  //   text-align: center;
  //   letter-spacing: 12px;
  //   margin-bottom: 120px;
  // }
  .use-zen-link-row {
    padding: 3rem 15px;
    background-image: url("../img/connected-lines-horizontal.png");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .use-zen-link {
    &:nth-of-type(2) {
      .use-zen-link-block {
        animation-delay: 1s;
      }
    }
    &:last-of-type {
      .use-zen-link-block {
        animation-delay: 2s;
      }
    }
  }
  .use-zen-link-block {
    display: block;
    // max-width: 340px;
    margin: 0 auto;
    padding: 3rem 0;
    &:hover {
      text-decoration: none;
      background-color: rgba($blue, .25);
    }
    .icon {
      width: 104px;
    }
    h4 {
      color: white;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 6px;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
  .lined-subtext {
    color: white;
    &:before,
    &:after {
      background-color: white;
    }
    &.green {
      color: $green;
      &:before,
      &:after {
        background-color: $green;
      }
    }
  }
  // .feature-row {
  //   max-width: 1000px;
  //   margin: 0 auto;
  // }
  .feature-item {
    margin: 3rem 0;
    @media (min-width: 576px) {
      padding: 0 4rem;
    }
    h4 {
      color: $green;
      font-weight: 700;
      font-size: 28px;
      margin-top: 0;
      // margin-bottom: 0;
      text-transform: none;
    }
    ul {
      li {
        color: white;
        font-weight: 400;
        font-size: 22px;
        line-height: 1.3;
        padding: 0;
        padding-bottom: 1.4rem;
        margin-left: 0;
        &:before {
          display: none;
        }
      }
    }
    p {
      color: white;
      font-weight: 100;
      font-size: 22px;
    }
    a {
      color: $green;
    }
  }
}

#mining-pools,
#use-zen {
  div[class^='col-'] {
    padding: 0 15px;
  }
  .card-body {
    padding: 3rem 1.875rem;
    img {
      max-width: 60px;
      max-height: 60px;
    }
    h3 {
      color: #1b2f59;
      font-size: 28px;
      font-family: proxima-nova,sans-serif !important;
      line-height: 32px;
      letter-spacing: 0;
      font-weight: 100;
      text-transform: none;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
  .card-footer {
    display: flex;
    background-color: #eee;
    padding: 0;
    border-radius: 0;
    a {
      flex-grow: 1;
      color: #1b2f59;
      text-transform: uppercase;
      font-weight: 700;
      display: block;
      padding: 1rem 1rem;
      &:not(:only-of-type) {
        &:first-of-type {
          border-right: 0.5px solid #041435;
        }
        &:last-of-type {
          border-left: 0.5px solid #041435;
        }
      }
      &:hover,
      &:focus {
        background-color: $green;
      }
      .right-arrow {
        width: 10px;
        transform: rotate(-90deg);
        margin-left: 8px;
        margin-top: -3px;
      }
    }
  }
}

.card-col {
  margin: 1rem 0;
}
.card {
  display: flex;
  height: 100%;
  border-radius: 0;
}
.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.card-footer {
  border-radius: 0;
}

#team-overview {
  background-color: $darkblue;
  padding-top: 0;
  padding-bottom: 0;
  h2 {
    color: $darkblue;
    font-size: 2rem;
    font-weight: 900;
    letter-spacing: 5px;
  }
  .subhead-large {
    color: $darkblue;
  }
  .lined-subtext {
    color: $green;
    &:before,
    &:after {
      background-color: $green;
    }
  }
  .core-team-row {
    padding-bottom: 15px;
  }
  div[class^='col-'] {
    padding: 15px;
  }
  .card-body {
    padding: 2.4rem 1.875rem;
  }
  .headshot {
    width: 150px;
    height: 150px;
    border-radius: 5rem;
    @include bgimagecover(white, '', center);
    @include grayscale_element(100%);
  }
  h3 {
    color: #1b2f59;
    font-size: 28px;
    line-height: 32px;
    font-weight: 200;
    letter-spacing: 0;
    text-transform: none;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  h4 {
    text-transform: none;
    color: #1b2f59;
    font-size: 16px;
    font-weight: 700;
  }
  .social-icons {
    a {
      display: inline-block;
      height: 50px;
      width: 50px;
      line-height: 50px;
      font-size: 30px;
      color: white;
      background-color: $green;
      border-radius: 5rem;
      margin: 6px 6px 0;
      &:hover,
      &:focus {
        background-color: #36c083;
      }
    }
  }
  .global-team {
    padding-top: 109px;
    padding-bottom: 100px;
    background-color: #69ffbd;
    .lined-subtext {
      color: #185e94;
      &:before,
      &:after {
        background-color: #185e94;
      }
    }
    .team-map {
      display: block;
      margin: 0 auto;
    }
    .btn-inverse {
      &:hover,
      &:focus {
        background-color: white;
      }
    }
  }
  .team-rows {
    padding-top: 4rem;
    .headshot {
      height: 100px;
      width: 100px;
    }
    .card-body {
      h3 {
        margin-bottom: 8px;
        font-size: 18px;
      }
    }
  }
}

#team-details {
  background-color: #041435;
  background-image: linear-gradient(180deg, rgba(4, 20, 53, .8), rgba(4, 20, 53, .8)), url('../img/bkg/team.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-attachment: scroll, fixed;
  padding-top: 120px;
  padding-bottom: 120px;
  .interior-subheader {
    h1 {
      text-transform: uppercase;
    }
    h4 {
      color: white;
      font-weight: 700;
      font-size: 1rem;
      letter-spacing: 5px;
    }
  }
  .team-section {
    margin-top: 4rem;
    // padding-left: 0;
    // padding-right: 0;
    .lined-subtext {
      color: $green;
      font-weight: 700;
      font-size: 1.6rem;
      margin-bottom: 1.6rem;
      &:before,
      &:after {
        background-color: $green;
      }
    }
  }
  .team-group {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    div[class^="col-"] {
      padding: 1px;
    }
  }
  .team-card {
    font-family: proxima-nova,sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
    background-color: white;
    padding: 5rem 0;
    .team-bio {
      position: relative;
      padding: 0 1rem;
    }
    h1, h2, h3, h4, h5, p {
      font-family: proxima-nova,sans-serif;
    }
    h3 {
      font-weight: 700;
      color: #1b2f59;
      font-size: 1.2rem;
      letter-spacing: 6px;
      margin-bottom: .3rem;
    }
    .headshot {
      width: 200px;
      height: 200px;
      border-radius: 10rem;
      margin: 0 auto 1.4rem;
      @include bgimagecover(white, '', center);
      // @include grayscale_element(100%);

      transform-style: preserve-3d;
      transition: transform 200ms;
      transform: scaleX(1) scaleY(1) scaleZ(1);
    }
    .title {
      font-size: 1.2rem;
    }
    .profile-link {
      // margin-top: .4rem;
      min-height: 40px;
      transform-style: preserve-3d;
      transition: transform 200ms, opacity 200ms;
      opacity: 0;
      transform: translateX(0px) translateY(20px) translateZ(0px);
    }
    .social-links {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      transform-style: preserve-3d;
      transition: transform 200ms, opacity 200ms;
      opacity: 0;
      transform: translateX(0px) translateY(10px) translateZ(0px);
      .social-link {
        font-weight: 700;
        a {
          color: $text-color;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover,
          &:focus {
            color: $lightblue;
            text-decoration: none;
          }
        }
        img {
          width: 35px;
          height: 35px;
        }
        &.linkedin {
          img {
            width: 30px;
            height: 30px;
          }
        }
        &.discord {
          &:not(:only-of-type) {
            margin-left: 20px;
          }
          img {
            margin-right: 4px;
          }
        }
        &.wechat {
          &:not(:only-of-type) {
            margin-left: 20px;
          }
          img {
            margin-right: 4px;
          }
        }
        &.background {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 5rem;
            margin: 0 4px;
            &:hover,
            &:focus {
              background-color: $green;
            }
          }
        }
      }
    }
    &:hover {
      .headshot {
        transform: scaleX(1.04) scaleY(1.04) scaleZ(1);
        -webkit-backface-visibility: hidden;
        -webkit-font-smoothing: subpixel-antialiased;
      }
      .profile-link {
        opacity: 1;
        transform: translateX(0px) translateY(0px) translateZ(0px);
      }
      .social-links {
        opacity: 1;
        transform: translateX(0px) translateY(-10px) translateZ(0px);
      }
    }
  }
  #team-details-modal {
    background-color: rgba(4,20,53,0);
    @include transition(all 0.3s ease-in-out);
    &.show {
      background-color: rgba(4,20,53,0.6);
      @include transition(all 0.3s ease-in-out);
    }
    .modal-dialog {
      height: 100%;
      margin: 0 auto;
      @media (min-width: 576px) {
        max-width: 650px;
      }
    }
    .modal-body {
      text-align: center;
      border: 10px solid #69ffbd;
      border-radius: 0;
      padding: 3rem 1.5rem;
      h3 {
        color: #1b2f59;
        font-size: 1.2rem;
        letter-spacing: 6px;
        margin-bottom: .3rem;
      }
      .headshot {
        width: 200px;
        height: 200px;
        border-radius: 10rem;
        margin: 0 auto 1.4rem;
        @include bgimagecover(white, '', center);
        // @include grayscale_element(100%);

        transform-style: preserve-3d;
        transition: transform 200ms;
        transform: scaleX(1) scaleY(1) scaleZ(1);
      }
      #modal-title {
        font-size: 1.2rem;
      }
      #modal-bio {
        margin-top: 2rem;
      }
      .social-links {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        .social-link {
          font-weight: 700;
          a {
            color: $text-color;
            &:hover,
            &:focus {
              color: $lightblue;
              text-decoration: none;
            }
          }
          img {
            width: 35px;
            height: 35px;
          }
          &.linkedin {
            img {
              width: 30px;
              height: 30px;
            }
          }
          &.discord {
            &:not(:only-of-type) {
              margin-left: 20px;
            }
            img {
              margin-right: 4px;
            }
          }
          &.background {
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 60px;
              height: 60px;
              border-radius: 5rem;
              margin: 0 4px;
              &:hover,
              &:focus {
                background-color: $green;
              }
            }
          }
        }
      }
    }
  }
  #team-details-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;

    display: block;
    width: 70px;
    height: 70px;
    margin-left: auto;
    padding-top: 15px;
    padding-right: 16px;
    padding-left: 16px;
    float: none;
    clear: none;
    background-color: transparent;
    .x {
      position: static;
      top: 0px;
      right: 0px;
      display: inline-block;
      width: 41px;
      height: 1px;
      margin-left: -37px;
      float: right;
      background-color: #000;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      text-align: center;
    }
    .x2 {
      margin-left: 0px;
      float: right;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      text-align: left;
    }
  }
}

#team {
  // section.content {
  //   padding: 5rem 0 0;
  // }
  .team-cards {
    color: white;
    background-color: $darkblue;
    margin-top: 4rem;
    padding: 3rem 15px;
    .lined-subtext {
      color: $green;
      &::before,
      &::after {
        background-color: $green;
      }
    }
    div[class^='col-'] {
      padding: 15px;
    }
    .card-body {
      padding: 2.4rem 1.875rem;
    }
    .headshot {
      width: 150px;
      height: 150px;
      border-radius: 5rem;
      @include bgimagecover(white, '', center);
      @include grayscale_element(100%);
    }
    h3 {
      color: #1b2f59;
      font-size: 28px;
      line-height: 32px;
      font-weight: 200;
      letter-spacing: 0;
      text-transform: none;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
    h4 {
      text-transform: none;
      color: #1b2f59;
      font-size: 16px;
      font-weight: 700;
    }
    .social-icons {
      text-align: center;
      a {
        display: inline-block;
        height: 50px;
        width: 50px;
        line-height: 50px;
        font-size: 30px;
        color: white;
        background-color: $green;
        border-radius: 5rem;
        margin: 6px 6px 0;
        &:hover,
        &:focus {
          background-color: #36c083;
        }
      }
    }
  }
}

#research, #whitepapers {
  h3 {
    color: #041742;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 0;
  }
  .container {
    @media (min-width: 1300px) {
      max-width: 1240px;
    }
  }
  section.content h2:not(.lined-subtext) {
    font-size: 38px;
    margin-bottom: 1.6rem;
    @media (max-width: 767px) {
      font-size: 28px;
    }
  }
  .whitepaper-item {
    margin: 4rem -15px;
  }
  .whitepaper-image {
    @media (min-width: 1200px) {
      padding-right: 3rem;
    }
    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 2rem;
      img {
        max-height: 200px;
      }
    }
  }
  .whitepaper-intro {
    @media (min-width: 1200px) {
      padding-left: 3rem;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      color: #041742;
      font-size: 1.6rem;
      font-weight: 600;
      letter-spacing: 0;
      text-transform: none;
      margin-bottom: 0.5rem;
    }
    h5 {
      color: #041742;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0;
      text-transform: none;
      margin-bottom: 1rem;
    }
    .intro {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .btn {
      text-transform: none;
      font-size: 1rem;
      font-weight: 300;
      padding: 1.2rem 3rem;
    }
  }
  img.whitepaper {
    box-shadow: -8px 8px 20px 0px rgba(0,0,0,0.08);
  }
  .interior-header {
    background-image: url("../img/bkg/research-header-bkg.jpg");
    background-size: cover;
    background-position: top center;
  }
  .card {
    background-color: #f5f5f5;
    h3 {
      color: $darkblue;
      font-size: 1.2rem;
      letter-spacing: 4px;
      line-height: 1.4;
    }
    p {
      font-weight: 300;
    }
    .btn {
      margin-top: 1rem;
    }
    .btn-lg {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .inline-buttons {
      .btn {
        margin-left: 6px;
        margin-right: 6px;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
  .email-form-wrapper {
    padding-top: 4rem;
  }
  #email-form-wrapper-sticky {
    background-color: #e7e8ec;
    padding: 20px 10px 20px 20px;
    &.sticky_fixed {
      position: fixed;
      top: 0;
    }
    &.sticky_absolute {
      position: absolute;
      bottom: 0;
    }
    h3 {
      text-transform: none;
      font-size: 1.2rem;
    }
  }
  #email-form-research {
    .form-group-inline {
      display: table;
      width: 100%;
      & > label {
        display: table-cell;
        white-space: nowrap;
        padding-right: 8px;
        width: 1%;
      }
      & > input, & > select {
        display: table-cell;
        width: 100%;
      }
      & > .required-mark {
        display: table-cell;
        width: 20px;
        padding-left: 4px;
      }
      & > .select2-container {
        width: 100% !important;
      }
    }
    .form-control-label {
      font-weight: bold;
      font-size: 1rem;
      & > .required-mark {
        font-weight: normal;
        display: inline-block;
        width: 8px;
        text-align: right;
      }
    }
    .form-check-label {
      padding-left: 0.25rem;
      font-size: 1rem;
    }
    input.form-control-sm {
      padding: 0.15rem 0.3rem;
      border-radius: 0;
    }
    .select2-container .select2-selection--single {
      border-radius: 0;
      height: 25px;
      border-color: rgb(190, 202, 214);
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #8a9299;
      line-height: 25px;
      font-size: 14px;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: 24px;
    }
    textarea {
      border-radius: 0;
      padding: 0.35rem 0.25rem;
    }
    .btn-primary {
      background-color: #0b1241;
      text-transform: none;
    }
  }
}

#whitepapers {
  #modal-subscribe {
    .modal-header {
      border-bottom: none;
    }
    .modal-content {
      border-radius: 0;
      .form-group {
        .form-control-label {
          font-weight: bold;
          .required-mark {
            font-weight: normal;
            display: inline-block;
            width: 8px;
            text-align: right;
          }
        }
        .form-control-sm, .form-control {
          border-radius: 0;
        }
        .select2-container {
          width: 100% !important;
          .select2-selection--single {
            border-radius: 0;
            border-color: #becad6;
          }
        }
        .select2-container--default .select2-selection--single .select2-selection__rendered {
          color: #8a9299;
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    section.content.intro {
      color: #1B2F5A;
      padding-bottom: 0;
      margin-bottom: -4rem;
      p {
        color: #1B2F5A;
        font-size: 19px;
      }
      hr {
        margin: 3rem 0;
      }
    }
    h3 {
      color: #1B2F5A;
      line-height: 1.2;
      font-weight: 100;
      letter-spacing: 0;
      text-transform: none;
      margin-bottom: 1.6rem;
      font-size: 1.8rem;
      text-align: center;
    }
    .form-header {
      background-image: linear-gradient(90deg, #042b78, #041742);
      padding: 10px;
      text-align: center;
      h2 {
        color: #fff;
        font-weight: 600;
        line-height: 1.3;
        text-transform: uppercase;
        margin-bottom: 0 !important;
        font-size: 1.2rem !important;
      }
    }
    .form-body {
      color: #041742;
      font-size: 1.1rem;
      padding: 20px 15px;
      input, select {
        border: 1px solid #e6e8ed;
        padding: 5px 10px;
      }
      .mc-field-group {
        > label, strong {
          font-weight: 600;
          font-size: 1.1rem;
        }
        &.row {
          flex-wrap: nowrap;
          margin-bottom: 1rem;
          label {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 40%;
            flex: 0 0 40%;
            max-width: 40%;
          }
          input, select {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 60%;
            flex: 0 0 60%;
            max-width: 60%;
          }
        }
      }
      .button {
        display: block;
        width: 100%;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fff;
        border: 0;
        background-image: linear-gradient(90deg, #0e9de5, #26db8d);
        padding: 10px;
        margin-top: 1rem;
        transition: all 0.3s ease-in-out;
        white-space: normal;
        &:hover {
          cursor: pointer;
          background-image: linear-gradient(90deg, #0e9de5, #0e9de5);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}


#error {
  padding-top: 0;
  padding-bottom: 0;
}

.site-footer {
  color: white;
  background-color: #041435;
  padding: 0 0 3rem;
  .footer-nav {
    max-width: 1600px;
    margin: 0 auto;
    a {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
    }
    div[class^="col-"] {
      padding: 0 2rem;
    }
  }
  .email-prefooter {
    background: linear-gradient(110deg, rgba(#074175, 1), rgba($h-light-blue, 1), rgba(#20cca3, 1));
    background-blend-mode: multiply;
    margin-bottom: 3rem;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    .btn-primary {
      background-color: $h-dark-blue;
    }
    #email-form-footer {
      .col {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        &:not(.submit) {
          flex: 0 0 18%;
          max-width: 18%;
        }
      }
    }
    .community-footer {
      max-width: 1600px;
      margin: 0 auto;
      padding: 4rem 0 2rem;
      h2 {
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: 2px;
        margin-bottom: 2rem;
      }
      .social-link {
        text-align: center;
        margin: 1rem auto;
        img {
          margin-bottom: 2rem;
        }
        .btn {
          text-transform: none;
          padding: 0.5rem 1rem;
          &:hover,
          &:focus {
            color: $h-dark-blue;
          }
        }
      }
    }
  }
  .logo {
    display: block;
    max-width: 80%;
    margin: 2rem 0;
  }
  h2, h3 {
    color: white;
    font-size: 1rem;
    letter-spacing: 3px;
    text-align: center;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 400;
  }
  // h2 {
  //   padding-bottom: 1rem;
  //   border-bottom: 1px solid white;
  // }
  a {
    color: white;
    &.blue {
      color: $h-light-blue;
    }
    &.nav-link {
      line-height: 52px;
      min-height: 52px;
      padding: 0;
    }
    &:hover,
    &:focus {
      color: $h-light-blue;
    }
  }
  .contact-us {
    border-right: 3px solid $h-blue;
  }
  .resources {
    .flex-column {
      flex-wrap: wrap;
      max-height: 230px;
    }
  }
  .nav-item {
    font-size: 1rem;
  }
  .community-columns {
    max-width: 480px;
    margin: 0 auto;
    @media (min-width: 2200px) {
      max-width: none;
      .nav-link {
        justify-content: center;
      }
    }
    .nav-item {
      text-align: left;
    }
    .nav-link {
      display: flex;
      align-items: center;
      // justify-content: center;
      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-right: .8rem;
      }
      img {
        display: block;
        max-width: 30px;
        max-height: 30px;
        margin: 0 auto;
      }
      i {
        font-size: 1.6rem;
      }
    }
  }
  .social-media-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 55px;
    border-radius: 5rem;
    &:hover {
      background-color: #1b2f59;
    }
    img {
      max-height: 35px;
      max-width: 35px;
    }
  }
  #email-form-footer {
    padding: 1.6rem 0;
    .form-control {
      color: $h-dark-blue;
      font-weight: 400;
      height: 50px;
      background-color: #ebebeb;
      border: 0;
      border-radius: 0;
      padding: .75rem;
    }
    .form-group {
      margin-bottom: 0;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: darken(white, 35%);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: darken(white, 35%);
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: darken(white, 35%);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: darken(white, 35%);
    }
    .select2-container--default {
      width: 100% !important;
      .select2-selection--single {
        font-weight: 400;
        height: 50px;
        background-color: #ebebeb;
        border: 0;
        border-radius: 0;
        padding: .75rem;
        text-align: left;
      }
    }
    .select2-container .select2-selection--single .select2-selection__rendered {
      color: darken(white, 35%);
      padding-left: 0;
      &:not([title^="Your Country"]) {
        color: $h-dark-blue;
      }
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: 50px;
      width: 36px;
    }
    .submit {
      max-width: 400px;
    }
    .submit_container {
      height: 100%;
    }
    .btn {
      padding: 0.85rem 2rem;
      margin-top: 0;
      text-transform: none;
      letter-spacing: 2px;
      width: 100%;
      height: 100%;
    }
  }
  .copyright {
    margin-top: 2rem;
    small {
      color: #185e94;
      a {
        color: white;
        text-decoration: underline;
        padding: 0 3px;
      }
    }
  }
}

.team-page-header-links {
  margin-top: 3rem;
  @media (max-width: 575px) {
    margin-top: 1rem;
  }
  .col {
    text-align: center;
  }
  .team-page-link {
    display: block;
    color: white;
    padding: 15px 0;
    h1 {
      font-size: 1.4rem;
      letter-spacing: 3px;
      color: white;
      margin-bottom: 0;
      @media (max-width: 575px) {
        font-size: 1rem;
        letter-spacing: 0;
      }
    }
  }
}

#backToTop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  // background-color: rgba($green, 0.3);
  height: 60px;
  width: 60px;
  text-align: center;
  // border-radius: 5rem;
  opacity: 0;
  visibility: hidden;
  @include transition(all 0.2s ease-in-out);
  @media (max-width: 991px) {
    height: 40px;
    width: 40px;
  }
  &.show {
    opacity: 0.6;
    visibility: visible;
    @include transition(all 0.2s ease-in-out);
    @media (max-width: 991px) {
      opacity: 1;
    }
    &:hover,
    &:focus {
      opacity: 1;
      @include transition(all 0.2s ease-in-out);
    }
  }
  a {
    display: inline-block;
    // padding-top: 6px;
    // padding-left: 4px;
    img {
      height: 60px;
      width: 60px;
      @media (max-width: 991px) {
        height: 40px;
        width: 40px;
      }
    }
  }
}

@media (min-width: 992px) and (max-height: 900px) {
  .jumbotron {
    @include transition(all 0.2s ease-in-out);
    h1, h2, h3, .btn-lg {
      @include transition(all 0.2s ease-in-out);
    }
    .social-sidebar {
      padding-top: 0;
      padding-bottom: 0;
      @include transition(all 0.2s ease-in-out);
    }
    &.newsletter-visible {
      padding-top: 220px;
      @include transition(all 0.2s ease-in-out);
      h1, h2, h3, .btn-lg {
        @include transition(all 0.2s ease-in-out);
      }
      h1 {
        font-size: 54px;
      }
      h2 {
        font-size: 28px;
      }
      h3 {
        font-size: 16px;
      }
      .btn-lg {
        padding: 18px 34px;
      }
      .social-sidebar {
        padding-top: 220px;
        padding-bottom: 106px;
        @include transition(all 0.2s ease-in-out);
      }
    }
  }
}

@media (max-width: 1199px) {
  .site-header {
    .navbar-expand-lg {
      .navbar-nav {
        .nav-link {
          font-size: 0.9rem;
          padding-left: 8px;
          padding-right: 8px;
          &.dropdown-toggle {
            padding-right: 21px;
          }
        }
      }
    }
  }
  .dropdown-toggle::after {
    top: 17px;
  }
  // .site-footer {
  //   .email-updates {
  //     margin-top: 3rem;
  //   }
  // }
}

@media (max-width: 991px) {
  #home-header-close {
    width: 40px;
    height: 40px;
    padding: 0;
    right: 20px;
    .x {
      width: 20px;
    }
  }
  .site-header {
    border: 0;
    .navbar {
      padding: 0;
      background-color: $h-dark-blue;
      border: 0;
    }
    .navbar-brand {
      max-width: 160px;
      margin: 0 auto;
      padding-left: 0;
      padding-top: 14px;
      padding-bottom: 12px;
    }
    .hamburger {
      position: absolute;
      top: 0;
      right: 0;
      background: transparent;
      border: 0;
      padding-top: 14px;
      margin-top: 3px;
    }
    .hamburger-box {
      width: 30px;
    }
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      height: 2px;
      width: 30px;
      background-color: white;
    }
    .navbar-collapse {
      background-color: $h-blue;
      .nav-item {
        margin: 10px 0;
        font-size: 16px;
      }
      #chooseLanguage {
        padding: 0 !important;
        line-height: 43px;
        // font-size: 16px;
        // padding-left: 40px;
        // padding-right: 40px;
        // margin-left: 0;
        // margin-bottom: 30px;
      }
    }
    .nav-item:not(:last-of-type) .nav-link:before {
      display: none;
    }
  }
  .jumbotron {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    min-height: calc(100vh - 60px);
    #bgvid {
      background-position: 15%;
      background-image: url('../img/hero-bkg-mobile.jpg');
      video {
        display: none;
      }
    }
    .content-block {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      align-content: center;
    }
    .social-sidebar {
      flex-direction: row;
      justify-content: center;
      position: relative;
      right: auto;
      width: 100%;
      padding-bottom: 1rem;
    }
    // .btn-ghost {
    //   margin-bottom: 1.4rem;
    // }
  }
  .social-sidebar {
    .social-icon {
      margin: .6rem .3rem;
    }
  }
  #about {
    [data-lity] {
      img {
        display: block;
        margin: 0 auto 2rem;
      }
    }
  }
  #overview,
  #partners-and-investors,
  #white-paper {
    .container {
      max-width: 100%;
    }
  }
  #features {
    padding-left: 0;
    padding-right: 0;
    .feature-row {
      img {
        max-width: 100%;
      }
    }
  }
  #roadmap {
    .entry-icon {
      .icon-container {
        height: 80px;
        width: 80px;
      }
    }
    .entry-text {
      h3 {
        font-size: 30px;
        line-height: 1.4;
      }
    }
  }
  #team-overview {
    .card {
      max-width: 340px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  #use-zen {
    .feature-row {
      text-align: center;
      margin-top: 2rem;
      .col-lg-3 {
        &:first-of-type {
          .feature-item {
            margin-bottom: 0;
            ul {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .feature-item {
      margin: 1rem 0;
    }
  }
  #blog {
    .container {
      max-width: 100%;
    }
  }
  #resources {
    .subhead-image {
      max-width: 80%;
    }
  }
  .site-footer {
    a {
      &.nav-link {
        font-size: 0.8rem;
        line-height: 40px;
        min-height: 40px;
      }
    }
    .footer-nav div[class^="col-"] {
      padding: 0 15px;
    }
    .footer-nav .community-columns .col {
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
      .nav-link {
        line-height: 1.4;
      }
    }
    .contact-us {
      border-right: 0;
    }
    .community-columns {
      .nav-link {
        img {
          max-width: 20px;
          max-height: 20px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .subhead-large {
    font-size: 34px;
  }
  #about {
    .body-link .feature-icon-block {
      width: 90px;
      height: 90px;
    }
    .body-link .feature-icon {
      max-width: 50px;
      max-height: 50px;
    }
    .body-link h3 {
      font-size: 1.1rem;
    }
  }
  #white-paper {
    .col {
      flex: 0 0 100%;
      max-width: 100%;
      padding-top: 1rem;
    }
  }
  .btn-document {
    min-height: 80px;
  }
  section#features ul li {
    max-width: 100%;
  }
  #features {
    padding-top: 2rem;
    padding-bottom: 3rem;
    text-align: center;
    h3::after {
      margin: 1rem auto 0;
    }
    .feature-row {
      &.odd {
        .content {
          padding-left: 15px;
        }
      }
      &.even {
        text-align: left;
        .content {
          padding-right: 15px;
        }
      }
      .icon {
        img {
          display: block;
          max-width: 80%;
          width: auto;
          max-height: 400px;
          margin: 0 auto 4rem;
        }
      }
      .order-1 {
        order: 12;
      }
      .order-12 {
        order: 1;
      }
    }
  }
  #use-zen {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  #resources {
    h2 {
      &.subhead {
        font-size: 34px;
      }
    }
    .card {
      height: auto;
      margin: 1rem 0;
    }
  }
  #team-details {
    .team-card {
      padding: 3rem 0 5rem;
      .headshot {
        width: 150px;
        height: 150px;
      }
      .profile-link {
        opacity: 1;
        transform: translateX(0px) translateY(0px) translateZ(0px);
      }
      .social-links {
        opacity: 1;
        transform: translateX(0px) translateY(-10px) translateZ(0px);
        .social-link {
          a {
            height: 40px;
          }
          &.background {
            a {
              width: 40px;
              height: 40px;
            }
          }
          img {
            width: 25px;
            height: 25px;
          }
          &.linkedin {
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .site-footer {
    .email-prefooter {
      #email-form-footer {
        .col:not(.submit) {
          flex: 0 0 25%;
          max-width: 25%;
        }
        .submit {
          margin-top: 1rem;
          text-align: center;
        }
        .btn {
          width: auto;
        }
      }
    }
    .footer-nav {
      div[class^="col-"] {
        margin-bottom: 1rem;
      }
    }
  }
}

@media (max-width: 575px) {
  .site-header {
    &.headroom--top {
      #home-header {
        height: 100vh;
        opacity: 1;
        visibility: visible;
        background-color: rgba(white, .98);
        padding: 0 20px;
        @include transition(all 0.3s ease-in-out);
        .flex-column {
          display: flex;
          align-items: center;
        }
      }
    }
    #home-header {
      height: 0;
      opacity: 0;
      visibility: hidden;
      padding: 0 20px;
      @include transition(all 0.3s ease-in-out);
    }
  }

  section:not(#hero) {
    ul {
      li {
        font-size: 16px;
      }
    }
  }
  .jumbotron {
    h1 {
      font-size: 46px;
      line-height: 1;
      margin-bottom: 0;
    }
    h2 {
      font-size: 18px;
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
    }
    h3 {
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 1.3;
      margin: 1rem 0;
    }
    .lead {
      font-size: 1rem;
    }
    .btn-ghost {
      margin-bottom: 1.4rem;
    }
    .social-sidebar {
      padding-bottom: 0;
    }
    .featured-announcement {
      padding: 15px 15px 5px;
      .btn-lg {
        padding: 10px 20px;
      }
    }
    .announcement-footer {
      .lead {
        flex-direction: column;
      }
      span {
        display: block;
      }
      .btn {
        // margin-left: 0;
        // margin-top: 1rem;
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
  }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  #about {
    .lead {
      &.section-title {
        font-size: 1.4rem;
        // padding: 2rem 0;
      }
    }
    p {
      font-size: 1rem;
    }
    .icon-row {
      margin-top: 0;
    }
    .body-link {
      padding: 15px 0;
      .feature-icon {
        max-width: 50px;
      }
      h3 {
        font-size: 1rem;
        letter-spacing: 0px;
        margin-bottom: 8px;
      }
      .down-arrow {
        width: 12px;
        opacity: 1;
      }
    }
  }
  // .body-links {
  //   margin-bottom: 3rem;
  // }
  .zentext {
    padding: 1.4rem 0;
  }
  .zen-icon {
    max-width: 50%;
    padding: 2rem 0;
  }
  .about-zen {
    margin-bottom: 2rem;
    padding: 0 15px;
  }
  .zentext {
    .zenlink {
      padding: 0;
    }
  }
  #features {
    padding: 3rem 1rem;
    .feature-image {
      display: block;
      max-width: 40%;
      margin: 0 auto 2rem;
    }
    h3 {
      font-size: 20px;
    }
    .feature-row {
      padding: 1.2rem 0;
      &.odd {
        .content {
          padding-left: 15px;
        }
      }
      &.even {
        text-align: left;
        .content {
          padding-right: 15px;
        }
      }
    }
  }
  h2.section-title {
    margin-bottom: 2rem;
  }
  #partners-and-investors {
    .col {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      img {
        margin: 1rem 0;
      }
    }
  }
  #tech {
    padding: 3rem 15px 0;
    .technology-cards {
      margin-left: -30px;
      margin-right: -30px;
      .col {
        padding: 2rem 15px;
      }
      p {
        font-size: 1.2rem;
      }
    }
    .stats-section {
      margin-left: -30px;
      margin-right: -30px;
      .stats {
        .col {
          min-width: 100%;
          padding: 1rem 15px;
        }
      }
    }
    h3 {
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 6px;
    }
    .mb-5 {
      margin-bottom: 1rem !important;
    }
  }
  .subhead-large {
    font-size: 30px;
    line-height: 1.4;
  }
  #roadmap:not(body) {
    padding-top: 3rem;
    .zencash-logo {
      max-width: 70%;
    }
    .year-title {
      margin: 2rem 0;
      span {
        font-size: 20px;
        line-height: 1;
        padding: 20px 40px 20px 50px;
      }
    }
    .timeline-entry {
      width: 100%;
      margin: 2rem 0;
      &.odd {
        .entry-text {
          padding-left: 2rem;
        }
      }
      &.even {
        .entry-text {
          padding-right: 2rem;
        }
      }
    }
    .entry-text {
      h3 {
        font-size: 24px;
        line-height: 1.4;
      }
    }
    .entry-icon {
      .icon-container {
        height: 60px;
        width: 60px;
      }
    }
  }
  #use-zen {
    .section-title {
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 1.4;
      margin-bottom: 3rem;
    }
    .use-zen-link-row {
      padding-top: 1rem;
    }
    .use-zen-link {
      min-width: 100%;
    }
  }
  .lined-subtext {
    font-size: 16px;
  }
  #team-overview {
    // padding-top: 3rem;
    // padding-bottom: 3rem;
    .global-team {
      padding-top: 2rem;
    }
    .team-rows {
      padding-top: 2rem;
    }
  }
  #blog {
    #blogPosts {
      .col {
        min-width: 100%;
        padding: 0;
        margin-bottom: 1rem;
      }
    }
  }
  .site-footer {
    padding: 0 0 3rem;
    .logo {
      max-width: 200px;
      margin: 0 auto 2rem;
    }
    .contact-us {
      text-align: center;
    }
    .nav-item {
      text-align: center;
    }
    a {
      &.nav-link {
        font-size: 1rem;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .footer-nav {
    div[class^="col-"] {
      margin: 1rem 0;
    }
  }
  .site-footer {
    .email-prefooter {
      #email-form-footer {
        .col:not(.submit) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@media (max-width: 374px) {
  .jumbotron {
    display: block;
    min-height: 0;
    padding-top: 6rem;
    h1 {
      font-size: 42px;
    }
    h2 {
      font-size: 17px;
      letter-spacing: 1px;
      max-width: 100%;
      margin-bottom: 0.7rem;
    }
    h3 {
      font-size: 12px;
      margin: 0.8rem 0;
    }
    .btn-ghost {
      font-size: 12px;
      padding: 10px;
    }
  }
}

@media (hover: none) {
  #team-details {
    .team-card {
      .profile-link {
        opacity: 1;
        transform: translateX(0px) translateY(0px) translateZ(0px);
      }
      .social-links {
        opacity: 1;
        transform: translateX(0px) translateY(-10px) translateZ(0px);
      }
    }
  }
}

iframe[src="javascript:false"] {
  display: none;
}
