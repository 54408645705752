@charset "utf-8";

$dark-blue: #27498C;
$light-blue: #56B4D7;
$green: #97A626;
$yellow: #F2BE7E;
$red: #A62E2E;

// Our variables
$base-font-family: 'Roboto', sans-serif;
$base-font-size:   18px;
$base-font-weight: 300;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$header-font-family: 'Exo 2', sans-serif;
$header-font-weight: 600;

$spacing-unit:     30px;

$text-color:       #121F1F;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

$orange: #F88900;
$tan: #CCB18E;
$purple: #4E3D66;
$teal: #256468;
$darkteal: #174E50;
$gray: #f2f2f2;

// Horizen colors
$h-blue: #0a2561;
$h-light-blue: #0e9de5;
$h-dark-blue: #0b1241;


// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}
