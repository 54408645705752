/*
The main SCSS file for everything, yep
*/

@import "../../bower_components/normalize-css/normalize";
@import "vendor/bootstrap/bootstrap";
// @import "../../bower_components/animsition/dist/css/animsition";

@import "partials/variables";
@import "partials/mixins";

$base-font-family: proxima-nova, sans-serif;
$base-font-weight: 400;
$header-font-family: proxima-nova, sans-serif;
$header-font-weight: 100;

$green: #69ffbd;
$lightteal: #94ffd0;
$lightblue: rgb(42, 122, 226);
$blue: #203768;
$darkblue: #1B2F5A;
$gray: #a1a1a1;
$lightgray: #f3f3f3;

@import "vendor/animations";
@import "../../bower_components/animate.css/animate";
@import "../../bower_components/aos/dist/aos";
@import "../../bower_components/lity/dist/lity";
@import "../../bower_components/select2/dist/css/select2";
// @import "vendor/flags";
@import "vendor/hamburgers/hamburgers";
@import "vendor/font-awesome/font-awesome";
@import "vendor/shards/shards";

@import "partials/main";
@import "partials/base";
@import "partials/layout";
// @import "partials/syntax-highlighting";
// @import "partials/elements";
// @import "partials/modules";

@import "partials/homepage";
@import "partials/language-sidebar";
@import "partials/landing-page";

@import "partials/graphics";
@import "partials/localization";
