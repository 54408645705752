* {
  -webkit-font-smoothing: auto;
}

section.content {
  h2:not(.lined-subtext).large-section-title {
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 0.4rem;
    @media (max-width: 767px) {
      font-size: 2.4rem;
    }
  }
}

#what-is-horizen {
  .interior-header {
    @include bgimagecover($darkteal, '../img/bkg/what-is-horizen.jpg', center);
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }
  section.content {
    padding-bottom: 0;
  }
  section.content h2:not(.lined-subtext) {
    color: $h-dark-blue;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 2rem;
  }
  .lead {
    font-size: 1.2rem;
  }
  section.content {
    .our-mission {
      h2:not(.lined-subtext) {
        color: white;
        text-transform: uppercase;
        &:after {
          display: block;
          content: '';
          height: 3px;
          width: 100px;
          background-color: $h-light-blue;
          margin-top: 1rem;
        }
      }
    }
  }
  .payments {
    color: white;
    background-color: $h-dark-blue;
    background-image: linear-gradient(to right, rgba($h-dark-blue, 1), rgba($h-blue, 0.4)), url('../img/bkg/shopping-bg.png');
    background-repeat: no-repeat;
    background-position: left center, right center;
    // background-size: auto 100%;
    padding: 5rem 0;
    h2 {
      &:after {
        display: block;
        content: '';
        height: 3px;
        width: 100px;
        background-color: $h-light-blue;
        margin-top: 1.5rem;
      }
    }
    .content {
      padding-left: 3rem;
    }
  }
  section.content {
    .payments {
      h2:not(.lined-subtext) {
        color: white;
        text-transform: uppercase;
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: 2px;
        margin-bottom: 1.5rem;
      }
    }
  }
  .responsible-privacy {
    padding: 4rem 0;
    @include bgimagecover(white, '../img/bkg/wavy.jpg', center);
    h2 {
      &:after {
        display: block;
        content: '';
        height: 3px;
        width: 160px;
        background-color: $h-light-blue;
        margin: 1rem auto 0;
      }
    }
    h4 {
      color: $h-dark-blue;
      text-transform: none;
      font-weight: 500;
      margin-bottom: 1.5rem;
    }
    ol {
      font-size: 1.4rem;
      font-weight: 300;
      li:not(:last-of-type) {
        margin-bottom: 1.4rem;
      }
    }
  }
  section.content {
    .responsible-privacy {
      h2:not(.lined-subtext) {
        color: $h-dark-blue;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 1.5rem;
      }
    }
  }
  .team {
    color: white;
    background-color: $h-dark-blue;
    background-image: url('../img/bkg/horizen-team.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 4rem 0;
    h2 {
      &:after {
        display: block;
        content: '';
        height: 3px;
        width: 160px;
        background-color: $h-light-blue;
        margin-top: 1rem;
      }
    }
    .lead {
      font-weight: 200;
      margin-bottom: 1.5rem;
    }
    .btn-white {
      color: $h-dark-blue;
      font-weight: 600;
      font-size: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
      &:hover,
      &:focus {
        color: white;
        background-color: $h-light-blue;
        box-shadow: none;
      }
    }
  }
  section.content {
    .team {
      h2:not(.lined-subtext) {
        color: white;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: 2px;
        margin-bottom: 2rem;
      }
    }
  }
  .our-mission {
    position: relative;
    overflow: hidden;
    color: white;
    background-color: $h-dark-blue;
    margin-top: 4rem;
    // @include bgimagecover($darkteal, '../vid/selfie.jpg', center);
    #sectionvid {
      animation-name: fadeIn;
      animation-fill-mode: both;
      animation-delay: 1.5s;
      animation-duration: 5s;

      overflow: hidden;

      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      transform: translateX(-50%) translateY(-50%);
      // @include bgimagecover($darkteal, '../vid/selfie.jpg', center);
      video {
        position: absolute;
        // top: -20%;
        // left: 50%;
        // min-width: 100%;
        // min-height: 100%;
        width: auto;
        height: 140%;
        // max-width: 100%;
        // max-height: 100%;
        // transform: translateX(-50%) translateY(-50%);
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
    .color-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: rgba(4, 20, 53, 0.7);
      background: linear-gradient(to right, rgba($h-light-blue, 0.3), rgba($h-dark-blue, 1));
      background-blend-mode: multiply;
    }
    .content {
      padding-top: 6rem;
      padding-bottom: 6rem;
      padding-right: 10rem;
      background: linear-gradient(to right, rgba($h-dark-blue, 0), rgba($h-dark-blue, 1), rgba($h-dark-blue, 1));
    }
    .lead {
      font-size: 1.4rem;
    }
  }
  section.content {
    .built-on-horizen {
      h2:not(.lined-subtext) {
        color: $h-dark-blue;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 3rem;
      }
    }
  }
  .built-on-horizen {
    padding: 5rem 0;
    background-image: url('../img/bkg/dots.png');
    background-size: 100%;
    background-repeat: repeat-y;
    // .horizen-overlay {
    //   position: absolute;
    //   top: 0;
    //   height: 100%;
    //   width: 100%;
    //   background-image: url('../img/bkg/horizen-overlay.png');
    //   background-size: auto 200%;
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   background-blend-mode: darken;
    // }
    .container-fluid {
      max-width: 1200px;
    }
    .products,
    .principles {
      div[class^="col-"] {
        padding: 1rem;
      }
      .product,
      .principle {
        padding: 2rem;
        h3 {
          color: white;
          font-size: 1.8rem;
          font-weight: 400;
          letter-spacing: 2px;
        }
      }
    }
    a.product {
      &:hover,
      &:focus {
        border-bottom: 0;
        text-decoration: none;
        background-color: $h-blue;
      }
    }
    .products {
      .product {
        display: block;
        height: 100%;
        color: white;
        background-color: $h-light-blue;
        h4 {
          color: white;
          font-weight: 400;
          font-size: 1.2rem;
          letter-spacing: 1px;
          text-transform: none;
          margin-bottom: 0;
        }
        p {
          font-weight: 100;
          margin-bottom: 0;
        }
      }
    }
    .principles {
      .principle {
        display: block;
        height: 100%;
        color: white;
        background-color: $h-dark-blue;
        &:hover,
        &:focus {
          border-bottom: 0;
          text-decoration: none;
          background-color: $h-blue;
        }
        h3 {
          margin-bottom: 0;
        }
      }
    }
  }
}

#zennodes {
  scroll-behavior: smooth;
  .interior-header {
    background-image: url("../img/bkg/zennodes.jpg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    h1 {
      &.logo-text {
        font-size: 6rem;
        letter-spacing: 0;
        text-transform: uppercase;
        @media (max-width: 767px) {
          font-size: 3.2rem;
          max-width: none;
        }
      }
    }
  }
  section.content.intro {
    color: #1B2F5A;
    padding-bottom: 0;
    margin-bottom: -4rem;
    p {
      color: #1B2F5A;
      font-size: 19px;
    }
    hr {
      margin: 3rem 0;
    }
  }
  h3 {
    color: #1B2F5A;
    font-size: 27px;
    line-height: 1.2;
    font-weight: 100;
    letter-spacing: 0;
    text-transform: none;
    margin-bottom: 1.6rem;
    @media (max-width: 767px) {
      font-size: 1.8rem;
      text-align: center;
    }
  }
  strong {
    font-weight: 600;
  }
  .node-types-section {
    background-image: url("../img/bkg/gray-nodes-2.png"), linear-gradient(180deg, #f5f5f5, #f5f5f5);
    background-size: cover;
    background-position: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .node-item {
    padding-top: 3rem;
    padding-bottom: 2.8rem;
    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    img {
      margin-bottom: 1rem;
    }
    h2 {
      color: #0e9de5;
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 0.2rem;
    }
    h4 {
      color: #041742;
      font-weight: 600;
      line-height: 1.2;
    }
    p {
      font-size: 1.1rem;
    }
    ul {
      li {
        font-size: 1.1rem !important;
        padding: 0.2rem 0 !important;
      }
    }
    .btn-lg {
      font-size: 1rem;
      font-weight: 200;
      letter-spacing: 1px;
      padding: 20px 15px;
      width: 100%;
    }
  }
  .sticky-parent {
    position: relative;
  }
  .sticky-form {
    position: absolute;
    top: 0;
    right: 0;
    backface-visibility: hidden;
    display: block;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    margin-bottom: 3rem;
    padding-left: 3rem;
    padding-right: 1rem;
  }
  .mobile-form {
    padding-bottom: 0;
  }
  #zennodes_form {
    background-color: #f2f3f7;
    border: 1px solid #e6e8ed;
  }
  .form-header {
    background-image: linear-gradient(90deg, #042b78, #041742);
    padding: 10px;
    text-align: center;
    h2 {
      color: #fff;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.3;
      text-transform: uppercase;
      margin-bottom: 0;
      @media (max-width: 767px) {
        font-size: 1.2rem;
      }
    }
  }
  .form-body {
    color: #041742;
    font-size: 1.1rem;
    padding: 30px 20px;
    @media (max-width: 767px) {
      padding: 20px 15px;
    }
    @media (min-width: 1200px) {
      padding: 30px;
    }
    input,
    select,
    textarea {
      border: 1px solid #e6e8ed;
    }
    input {
      padding: 5px 10px;
    }
    textarea {
      width: 100%;
      padding: 5px 10px;
    }
    .mc-field-group {
      > label, strong {
        font-weight: 600;
        font-size: 1.1rem;
      }
      &.row {
        flex-wrap: nowrap;
        margin-bottom: 1rem;
        label {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          -webkit-box-flex: 0;
          -ms-flex: 0 0 40%;
          flex: 0 0 40%;
          max-width: 40%;
        }
        input, select {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 60%;
          flex: 0 0 60%;
          max-width: 60%;
        }
      }
    }
    .input-group {
      ul {
        width: 100%;
        @media (max-width: 767px) {
          margin: 0 0 1rem;
        }
      }
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1rem !important;
        margin-left: 0 !important;
        padding: 0.2rem 0 !important;
        &:before {
          display: none !important;
        }
        input {
          margin-right: 0.6rem;
          flex-grow: 0;
        }
        label {
          font-size: 1rem;
          margin-bottom: 0;
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
        }
      }
      .row {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
    .button {
      display: block;
      width: 100%;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #fff;
      border: 0;
      background-image: linear-gradient(90deg, #0e9de5, #26db8d);
      padding: 10px;
      margin-top: 1rem;
      transition: all 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        background-image: linear-gradient(90deg, #0e9de5, #0e9de5);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

#philosophy,
#technology {
  .interior-header {
    position: relative;
    flex-direction: column;
    overflow: hidden;
    h1 {
      text-transform: uppercase;
    }
    #bgvid {
      animation-name: fadeIn;
      animation-fill-mode: both;
      animation-delay: 1.5s;
      animation-duration: 5s;

      height: 100%;
      width: 100%;
      video {
        position: absolute;
      }
    }
    .page-title {
      position: absolute;
      padding: 0 1rem;
    }
  }
}

#philosophy {
  .interior-header {
    @include bgimagecover($darkteal, '../vid/philosophy.jpg', center);
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}
#technology {
  .interior-header {
    @include bgimagecover($darkteal, '../vid/technology.jpg', center);
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}

section.content.forum-cta {
  color: white;
  background-color: $darkblue;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  margin-top: 4rem;
  @media (max-width: 767px) {
    margin-top: 2rem;
  }
  p {
    position: relative;
    margin-bottom: 0;
    a {
      color: $green;
    }
  }
  img {
    position: absolute;
    height: 50px;
    top: -40px;
    margin-left: -60px;
    @media (max-width: 767px) {
      display: none;
    }
    @media (min-width: 992px) {
      height: 74px;
      top: -50px;
      margin-left: -100px;
    }
  }
}

#supernodes {
  .interior-header {
    background-image: linear-gradient(180deg, rgba(4, 20, 53, .1), rgba(4, 20, 53, .5), rgba(14, 149, 123, .8)), url('../img/bkg/supernodes.jpg');
  }
  section.content {
    .intro-circle-div {
      img {
        max-width: 80%;
        max-height: 80%;
      }
    }
    &.gray {
      p {
        font-size: 1.2rem;
      }
    }
    &.radial {
      background-image: url('../img/bkg/radial-dots.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  #supernodes-box {
    margin-top: -7rem;
    margin-bottom: -8rem;
    margin-left: -4rem;
    position: relative;
    z-index: 99;
  }
  section.content {
    &.feature {
      background-image: linear-gradient(180deg, rgba(4, 20, 53, .8), rgba(4, 20, 53, .8)), url('../img/bkg/nodes.jpg');
      background-position: 0px 0px, 50% 50%;
      background-size: auto, cover;
      background-repeat: repeat, no-repeat;
      background-attachment: scroll, fixed;

      color: white;

      h3 {
        color: white;
        letter-spacing: 0;
        text-transform: none;
        font-size: 1.4rem;
        line-height: 1.4;
      }
      hr {
        border-top: 1px solid rgba($green,0.3);
      }
      .column-1 {
        &:before {
          content: '1';
        }
      }
      .column-2 {
        &:before {
          content: '2';
        }
      }
      .column-1,
      .column-2 {
        position: relative;
        padding-left: 4rem;
        &:before {
          position: absolute;
          top: 50%;
          color: $green;
          font-weight: 700;
          font-size: 1.4rem;
          margin-top: -17px;
          margin-left: -30px;
        }
        p {
          padding-left: 1rem;
          border-left: 2px solid $green;
          margin-bottom: 0;
        }
      }
      @media (max-width: 767px) {
        .column-1 {
          p {
            margin-bottom: 2rem;
          }
        }
        .node1,
        .node2 {
          margin-bottom: 2rem;
        }
        .row1 {

        }
        .row2 {
          .small-col {
            order: 1;
          }
          .large-col {
            order: 2;
          }
        }
      }
    }
    &.requirements {
      color: white;
      background-color: $darkblue;
      background-image: linear-gradient(180deg, rgba(4, 20, 53, .8), rgba(4, 20, 53, .8)), url('../img/bkg/nodes-2.jpg');
      background-position: 0px 0px, 50% 50%;
      background-size: auto, cover;
      background-repeat: repeat, no-repeat;
      background-attachment: scroll, fixed;

      .flex {
        display: flex;
        flex-direction: column;
      }
      h2 {
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 4rem;
        margin-bottom: 1rem;
        @media (max-width: 767px) {
          font-size: 2.4rem;
        }
      }
      h3 {
        color: $green;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
      .lead {
        color: white;
      }
      a {
        color: $green;
      }
    }

    .securenodes-title {
      width: 458px;
      max-width: 100%;
      margin-bottom: 0.6rem;
    }
  }
  .cta {
    padding-top: 2rem;
    padding-bottom: 2.4rem;
    h3 {
      color: $darkblue;
      text-transform: none;
      letter-spacing: 2px;
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
  }
  .feature-cta {
    padding-bottom: 0;
    h3 {
      color: $darkblue;
      text-transform: none;
      letter-spacing: 0;
      font-weight: 600;
    }
    .img-fluid {
      max-width: 220px;
    }
    @media (max-width: 767px) {
      img {
        margin-bottom: 2rem;
      }
    }
  }
  #requirements-graphic {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    margin-top: 2rem;
    @media (max-width: 767px) {
      margin-top: 1rem;
    }
    .requirement-item {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      text-align: center;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      h4 {
        color: white;
        text-transform: none;
        font-weight: 700;
        margin-bottom: 0;
      }
      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      margin-top: 30px;
      &:nth-of-type(-n+3) {
        margin-bottom: 50px;
        margin-top: 0;
      }

      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;

        margin-top: 15px;
        margin-bottom: 15px;
        &:nth-of-type(-n+3) {
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
      @media (min-width: 1200px) {
        padding: 0 4rem;
      }
      @media (min-width: 1500px) {
        padding: 0 8rem;
      }
    }
    .requirement-image {
      img {
        margin-right: -8px;
      }
    }
    .timeline-nodes {
      position: absolute;
      top: 50%;
      height: 50px;
      width: 100%;
      max-width: 83.3%;
      margin-top: -10px;
      margin-left: 0.5px;
      // padding: 0 6rem;
      @media (max-width: 767px) {
        display: none;
      }
      @media (min-width: 1024px) {
        margin-top: -25px;
      }
      @media (min-width: 1475px) {
        margin-top: -25px;
      }
      .node {
        position: relative;
        height: 50px;
        flex: 0 0 20%;
        max-width: 20%;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          height: 50%;
          width: 2px;
          background-color: #dcdcdc;
          margin-left: -1px;
        }
        &:nth-of-type(even) {
          &:before {
            top: 50%;
            margin-top: -1px;
          }
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          height: 2px;
          width: 100%;
          background-color: #dcdcdc;
          margin-top: -1px;
        }
        &:first-of-type {
          &:after {
            right: 0;
            width: 50%;
          }
        }
        &:last-of-type {
          &:after {
            width: 50%;
          }
        }
        span {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          height: 20px;
          width: 20px;
          background-color: #dcdcdc;
          border-radius: 5rem;
          margin-top: -10px;
          margin-left: -10px;
        }
      }
    }
  }
}
#supernodes {
   &.lng-es {
    .requirements {
      h2 {
        order: 2;
      }
      h3 {
        order: 1;
      }
    }
  }
}

#securenodes {
  .interior-header {
    background-image: linear-gradient(180deg, rgba(4, 20, 53, .1), rgba(4, 20, 53, .5), rgba(14, 149, 123, .8)), url('../img/bkg/securenodes.jpg');
  }
  section.content {
    &.intro {
      hr {
        margin-bottom: 4rem;
      }
      p {
        color: $darkblue;
        text-align: center;
      }
    }
    &.feature {
      background-image: linear-gradient(180deg, rgba(4, 20, 53, .8), rgba(4, 20, 53, .8)), url('../img/bkg/nodes.jpg');
      background-position: 0px 0px, 50% 50%;
      background-size: auto, cover;
      background-repeat: repeat, no-repeat;
      background-attachment: scroll, fixed;

      color: white;

      h3 {
        font-size: 1.4rem;
      }
      .column-1 {
        img {
          margin-bottom: 3rem;
        }
        p {
          font-size: 1.4rem;
        }
        @media (max-width: 767px) {
          text-align: center;
          margin-bottom: 30px;
          p {
            text-align: left;
          }
        }
      }
      .column-2 {
        p {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      @media (min-width: 768px) {
        .column-1 {
          padding-right: 4rem;
        }
        .column-2 {
          padding-left: 4rem;
          border-left: 2px solid $green;
        }
      }
    }
    .intro-circle-div {
      img {
        max-width: 80%;
        max-height: 80%;
      }
    }
    // h2:not(.lined-subtext) {
    //   font-size: 32px;
    //   @media (max-width: 767px) {
    //     font-size: 20px;
    //   }
    // }
    &.requirements {
      color: $darkblue;
      background-image: url('../img/bkg/securenodes-edges.png');
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: fixed;

      .flex {
        display: flex;
        flex-direction: column;
      }
      h3 {
        color: $darkblue;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
      .lead {
        color: $darkblue;
      }
    }

    .securenodes-title {
      width: 458px;
      max-width: 100%;
      margin-bottom: 0.6rem;
    }
  }
  .cta {
    padding-top: 2rem;
    padding-bottom: 2.4rem;
    h3 {
      color: $darkblue;
      text-transform: none;
      letter-spacing: 2px;
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
  }
  .feature-cta {
    padding-bottom: 0;
    h3 {
      color: $darkblue;
      text-transform: none;
      letter-spacing: 0;
      font-weight: 600;
    }
    .img-fluid {
      max-width: 220px;
    }
    @media (max-width: 767px) {
      img {
        margin-bottom: 2rem;
      }
    }
  }
  #requirements-graphic {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    margin-top: 2rem;
    @media (max-width: 767px) {
      margin-top: 1rem;
    }
    .requirement-item {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      text-align: center;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      h4 {
        color: $darkblue;
        text-transform: none;
        font-weight: 700;
        margin-bottom: 0;
      }
      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      margin-top: 30px;
      &:nth-of-type(-n+3) {
        margin-bottom: 50px;
        margin-top: 0;
      }

      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;

        margin-top: 15px;
        margin-bottom: 15px;
        &:nth-of-type(-n+3) {
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
      @media (min-width: 1200px) {
        padding: 0 4rem;
      }
      @media (min-width: 1500px) {
        padding: 0 8rem;
      }
    }
    .requirement-image {
      img {
        margin-right: -8px;
      }
    }
    .timeline-nodes {
      position: absolute;
      top: 50%;
      height: 50px;
      width: 100%;
      max-width: 83.3%;
      margin-top: -10px;
      margin-left: 0.5px;
      // padding: 0 6rem;
      @media (max-width: 767px) {
        display: none;
      }
      @media (min-width: 1024px) {
        margin-top: -25px;
      }
      @media (min-width: 1475px) {
        margin-top: -25px;
      }
      .node {
        position: relative;
        height: 50px;
        flex: 0 0 20%;
        max-width: 20%;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          height: 50%;
          width: 2px;
          background-color: #dcdcdc;
          margin-left: -1px;
        }
        &:nth-of-type(even) {
          &:before {
            top: 50%;
            margin-top: -1px;
          }
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          height: 2px;
          width: 100%;
          background-color: #dcdcdc;
          margin-top: -1px;
        }
        &:first-of-type {
          &:after {
            right: 0;
            width: 50%;
          }
        }
        &:last-of-type {
          &:after {
            width: 50%;
          }
        }
        span {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          height: 20px;
          width: 20px;
          background-color: #dcdcdc;
          border-radius: 5rem;
          margin-top: -10px;
          margin-left: -10px;
        }
      }
    }
  }
}

#zencash {
  .interior-header {
    h1.logo-text {
      text-transform: none;
    }
  }
}

.interior-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  min-height: 300px;
  max-height: 600px;
  background-color: #041435;
  background-image: linear-gradient(180deg, rgba(4, 20, 53, .8), rgba(4, 20, 53, .8)), url("../img/iohk-bkg.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  &.short {
    max-height: 400px;
  }
  img.logo {
    max-width: 450px;
    margin-bottom: 1rem;
  }
  h1 {
    color: white;
    font-size: 3.6rem;
    font-weight: 600;
    text-align: center;
    // letter-spacing: 0;
    // text-transform: none;
    max-width: 60vw;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    &.logo-text {
      font-size: 7.6rem;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1;
      letter-spacing: -6px;
      strong {
        font-weight: 600 !important;
      }
      @media (max-width: 767px) {
        font-size: 5rem;
        letter-spacing: -3px;
      }
      @media (max-width: 575px) {
        font-size: 3rem;
      }
    }
  }
  h2 {
    color: white;
    font-size: 2rem;
    letter-spacing: normal;
  }
  h3 {
    color: white;
    font-size: 1.4rem;
  }

  #bgvid {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // min-width: 100%;
    // min-height: 100%;
    // width: auto;
    // height: auto;
    // transform: translateX(-50%) translateY(-50%);

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .color-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(4, 20, 53, 0.7);
  }
}

.interior-subheader {
  text-align: center;
  padding-top: 4rem;
  h1 {
    color: white;
    // text-transform: none;
    letter-spacing: 15px;
    font-size: 5rem;
  }
}

.subsection {
  margin-bottom: 5rem;
  .subsection-title {
    margin-top: 0;
  }
}

.subsection-title {
  max-width: 60%;
  color: $darkblue;
  font-size: 1rem;
  text-align: center;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  padding-bottom: .6rem;
  border-bottom: 1px solid $darkblue;
  &:first-of-type {
    margin-top: 0;
  }
  @media (max-width: 575px) {
    max-width: 100%;
  }
}

section.content {
  position: relative;
  padding: 7rem 0;
  &.intro {
    padding-top: 12rem;
    padding-bottom: 3rem;
    &-2 {
      padding-top: 4rem;
    }
    h2 {
      margin-bottom: 3rem;
    }
    p {
      font-size: 1.4rem;
      font-weight: 300;
    }
  }
  &.gray {
    background-color: #f5f5f5;
    padding-bottom: 4rem;
    p {
      font-size: 1.4rem;
    }
    .text-right {
      text-align: center !important;
    }
  }
  &.legal {
    h2:not(.lined-subtext) {
      font-weight: 300;
      font-size: 28px;
    }
    h3 {
      color: #1B2F5A;
      letter-spacing: 1px;
      font-size: 22px;
    }
    ul, ol {
      li {
        font-size: 1rem !important;
        font-weight: 400 !important;
        padding: .4rem 0 !important;
      }
    }
  }
  ul {
    li {
      font-size: 1.4rem !important;
      font-weight: 300;
      padding: .8rem 0 !important;
    }
  }
  .intro-circle-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 250px;
    width: 250px;
    top: -90px;
    left: 50%;
    margin-left: -125px;
    background-color: white;
    border-radius: 12rem;
    img {
      max-width: 150px;
      max-height: 150px;
    }
  }
  h2:not(.lined-subtext) {
    color: $darkblue;
    font-size: 2.12rem;;
    line-height: 56px;
    font-weight: 100;
    letter-spacing: 0;
    text-transform: none;
  }
}

.zen-iohk {
  display: block;
  width: 417px;
  margin: 0 auto;
}

#infographic {
  color: white;
  background-color: #1b2f59;
  background-image: linear-gradient(180deg, rgba(4, 20, 53, .8), rgba(4, 20, 53, .8)), url("../img/iohk-bkg.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  h2 {
    color: white;
    font-size: 72px;
    line-height: 80px;
    font-weight: 100;
    text-align: center;
    text-transform: none;
    letter-spacing: 0;
  }
  .lead {
    font-size: 28px;
    line-height: 32px;
    font-weight: 100;
  }
  .iohk-infographic {
    display: block;
    margin: 0 auto;
    &.mobile {
      display: none;
    }
    &.desktop {
      display: block;
    }
  }
}

#scalability {
  background-color: #fff;
  background-image: url("../img/crazy-lines.png"), linear-gradient(180deg, #f8f8f8, #fff);
  background-position: 50% 50%, 0px 0px;
  background-size: cover, auto;
  background-repeat: no-repeat, repeat;
  background-attachment: scroll, scroll;
  text-align: center;
  .scalability-logo {
    width: 100px;
    margin-bottom: 2rem;
  }
  h2 {
    color: #185e94;
    font-size: 28px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 9px;
  }
  ul {
    li {
      color: #1b2f59;
      font-size: 28px !important;
      font-weight: 100;
      padding: 1rem 0 !important;
    }
  }
}

#iohk {
  #use-zen {
    background-image: linear-gradient(180deg, #1b2f59, #1b2f59);
  }
}

#zencash {
  .interior-header {
    background-image: linear-gradient(180deg, rgba(4, 20, 53, .6), rgba(4, 20, 53, .6)), url('../img/bkg/zencash.jpg');
    background-position: 0px 0px, 50% 50%;
  }
}
#zenchat {
  .interior-header {
    background-image: linear-gradient(180deg, rgba(4, 20, 53, .7), rgba(4, 20, 53, .7)), url('../img/bkg/zenchat.jpg');
    background-size: auto, cover;
  }
}
#zenpub {
  .interior-header {
    background-image: linear-gradient(180deg, rgba(4, 20, 53, .7), rgba(4, 20, 53, .7)), url('../img/bkg/zenpub.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
  }
}
#zenhide {
  .interior-header {
    background-image: linear-gradient(180deg, rgba(4, 20, 53, .2), rgba(4, 20, 53, .2)), url('../img/bkg/zenhide.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
  }
}
#zendao {
  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 25px;
    li {
      counter-increment: my-awesome-counter;
      margin-bottom: 1.2rem;
      position: relative;
      &:before {
        content: counter(my-awesome-counter) ". ";
        font-weight: bold;
        color: #0a2561;
        position: absolute;
        left: -25px;
      }
    }
  }
  .interior-header {
    background-image: url('../img/bkg/zendao.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  section.content.intro {
    hr {
      margin-bottom: 4rem;
    }
  }
  .content.cta {
    background-color: #f1f2f5;
    padding: 4rem 0;
    .btn {
      font-size: 1.4rem;
      font-weight: 100;
      font-family: proxima-nova, sans-serif;
      letter-spacing: 0;
      line-height: 1.2;
      padding: 18px 34px;
    }
  }
  #infographic {
    background-image: url(../img/bkg/blue-nodes.jpg);
    background-size: cover;
    background-position: right top;
    padding: 4rem 0;
    h2 {
      font-weight: bold;
      font-size: 3rem;
      line-height: 1.2;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    .lead {
      color: #29db8d;
      font-weight: 400;
      font-size: 1.4rem;
    }
    #treasury-model-graphic {
      margin-bottom: 0;
      .label {
        height: calc(46px + 2.2vw);
        span {
          background-color: #29db8d;
          border: none;
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }
      .step {
        .text {
          font-size: 1rem;
          font-weight: 300;
        }
        .icon {
          img {
            height: 70px;
          }
        }
      }
    }
  }
  .content.usage-chart {
    padding: 4rem 0;
    h2 {
      line-height: 1.3;
      font-size: 1.4rem;
      font-weight: 300;
      margin-bottom: 1.3rem;
    }
    .chart {
      h4 {
        border-bottom: 1px solid $grey-color-light;
        font-weight: bold;
        color: $h-blue;
        font-size: 1.4rem;
        font-style: italic;
        span {
          background-color: #fdfdfd;
          display: inline-block;
          position: relative;
          top: 0.9rem;
          padding-right: 15px;
        }
      }
      img {
        width: 150px;
      }
      .chart-legend {
        border: 1px solid $grey-color-light;
        padding: 15px 0;
        margin-top: 2rem;
        font-size: 0.8rem;
        & > div:before {
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          margin-right: 10px;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          border-radius: 100%;
        }
        .marketing:before {
          background-color: #2ed8d0;
        }
        .g-a:before {
          background-color: #5914b2;
        }
        .user-experience:before {
          background-color: #041742;
        }
        .business-dev:before {
          background-color: #26db8d;
        }
        .engineering:before {
          background-color: #0e9de5;
        }
      }
    }
    strong {
      font-weight: bold;
    }
  }
  .content.logic {
    padding: 4rem 0;
    background-color: #f1f2f5;
    h4 {
      text-transform: none;
      font-weight: bold;
      color: $h-blue;
    }
  }
}
#mining-pools {
  .interior-header {
    background-image: linear-gradient(180deg, rgba(4, 20, 53, .7), rgba(4, 20, 53, .7)), url('../img/bkg/miningpools.jpg');
    background-size: auto, cover;
  }
  strong {
    a {
      color: #185e94;
    }
    font-weight: 700;
  }
  .pool-list {
    background-color: #041435;
    padding: 4rem 0;
  }
}
#resources {
  .card {
    h3 {
      color: #1B2F5A;
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 4px;
      line-height: 1.4;
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
  .card-body {
    padding: 3rem 1.5rem 2.25rem;
  }
}

@media (max-width: 1199px) {
  .site-footer {
    .email-prefooter {
      .community-footer {
        .col {
          flex: 0 0 25%;
          max-width: 25%;
        }
        .social-link {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 2rem auto;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #supernodes {
    #supernodes-box {
      margin-left: 0;
    }
  }

  #bgvid {
    video {
      display: none;
    }
  }
  #philosophy,
  #technology {
    .interior-header {
      .page-title {
        position: relative;
      }
    }
  }
  .interior-header {
    height: auto;
    min-height: auto;
    max-height: none;
    padding: 8rem 0;
    h1 {
      font-size: 44px;
      // max-width: 80%;
      // margin-top: 8rem;
      // margin-bottom: 4rem;
    }
    h3 {
      font-size: 1rem;
    }
  }
  .interior-subheader {
    padding-top: 0;
    h1 {
      font-size: 44px;
    }
  }
  section.content {
    h2:not(.lined-subtext) {
      font-size: 24px;
      line-height: 1.2;
      // margin-bottom: 0;
    }
  }
  #infographic {
    h2 {
      font-size: 36px;
      line-height: 1.2;
      margin-bottom: 1rem;
    }
    .lead {
      font-size: 22px;
      line-height: 1.4;
    }
    .iohk-infographic {
      max-width: 500px;
      &.mobile {
        display: block;
      }
      &.desktop {
        display: none;
      }
    }
  }
  #scalability {
    h2 {
      margin-bottom: 2rem;
    }
    ul {
      li {
        font-size: 18px !important;
        padding: 0.4rem 0 !important;
      }
    }
  }
  .about-zen-interior {
    .zentext {
      h3 {
        font-size: 1rem;
      }
    }
  }
  .lined-subtext.mt-5 {
    margin-top: 1rem !important;
  }
}

@media (max-width: 767px) {
  #what-is-horizen .built-on-horizen .products .product h3,
  #what-is-horizen .built-on-horizen .products .principle h3,
  #what-is-horizen .built-on-horizen .principles .product h3,
  #what-is-horizen .built-on-horizen .principles .principle h3 {
    font-size: 1.6rem;
  }
  #supernodes {
    .supernodes-box-container {
      text-align: center;
    }
    #supernodes-box {
      margin: -6rem auto 2rem;
      max-height: 200px;
    }
  }
  .btn-lg {
    font-size: 14px;
    letter-spacing: 1px;
    padding: 10px 20px;
  }
  .interior-header {
    padding-bottom: 6rem;
    padding-left: 15px;
    padding-right: 15px;
    h1 {
      font-size: 28px;
      margin-top: 0;
      margin-bottom: 1rem;
    }
    h2 {
      font-size: 22px;
    }
    h3 {
      line-height: 1.6;
    }
    img {
      &.logo {
        max-width: 70%;
      }
    }
  }
  .interior-subheader {
    padding-top: 2rem;
    h1 {
      font-size: 22px;
    }
  }
  section.content {
    padding: 4rem 0 3rem;
    &.intro {
      padding-top: 8rem;
      p {
        font-size: 1rem;
        font-weight: 400;
      }
    }
    .intro-circle-div {
      height: 150px;
      width: 150px;
      top: -60px;
      margin-left: -75px;
      img {
        max-height: 100px;
        max-width: 100px;
      }
    }
    h2:not(.lined-subtext) {
      font-size: 22px;
      line-height: 1.2;
    }
    ul {
      margin: 1rem 0;
      li {
        font-size: 1rem !important;
        font-weight: 400 !important;
        padding: .5rem 0 !important;
      }
    }
  }
  .subsection {
    margin-bottom: 3rem;
  }
  .zen-iohk {
    max-width: 60%;
    margin-bottom: 3rem;
  }
  #infographic {
    h2 {
      font-size: 26px;
      line-height: 1.4;
    }
    .lead {
      font-size: 18px;
      line-height: 1.4;
    }
  }
  .about-zen-interior {
    padding-bottom: 3rem;
    .col {
      min-width: 100%;
      margin: 1rem 0;
    }
    .zentext {
      .zenlink {
        padding: 3rem 0;
      }
    }
  }
}

@media (max-width: 575px) {
  #supernodes {
    p.lead {
      font-size: 1.1rem;
    }
  }
  .interior-subheader {
    padding-top: 2rem;
    h1 {
      font-size: 1.4rem;
      letter-spacing: 0.8rem;
    }
  }
  #team {
    .interior-subheader {
      padding-top: 0;
    }
  }
  #team-details {
    .team-section {
      margin-top: 2rem;
    }
  }
  #infographic {
    .iohk-infographic {
      max-width: 100%;
    }
  }
  .site-footer {
    .email-prefooter {
      .community-footer {
        .col {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
  #what-is-horizen section.content h2:not(.lined-subtext) {
    font-size: 1.4rem;
  }
  #what-is-horizen .lead,
  #what-is-horizen .our-mission .lead,
  #what-is-horizen .responsible-privacy ol li:not(:last-of-type) {
    font-size: 1.2rem;
  }
  #what-is-horizen .our-mission .content {
    padding-right: 0;
  }
}

#spherebyhorizen {
  background-color: #fff;
  overflow-x: hidden;
  .interior-header {
    background-image: -webkit-linear-gradient(left, #164891, #082861);
    background-image: linear-gradient(90deg, #164891, #082861);
    #bgvid {
      height: 100%;
      width: 150%;
      margin-left: -150px;
      margin-right: -150px;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      overflow: hidden;
      position: relative;
    }
    .page-title {
      position: absolute;
      width: 50%;
      bottom: -30px;
    }
    .color-overlay {
      background: transparent;
    }
    .circle-border-bottom {
      background: transparent;
      width: 100%;
      height: 150px;
      position: absolute;
      bottom: 0;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
    }
  }
  section.content.intro {
    padding-top: 4rem;
    background-image: -webkit-linear-gradient(left, #164891, #082861);
    background-image: linear-gradient(90deg, #164891, #082861);
    color: white;
  }
  .intro {
    h3 {
      color: white;
      text-align: center;
      letter-spacing: 4px;
      font-size: 2.3125rem;
      font-weight: bold;
    }
    p {
      font-size: 1.5rem;
      font-weight: 100;
      line-height: 1.1;
    }
  }
  .download-app {
    color: white;
    p {
      font-size: 1.5rem;
      color: $h-light-blue;
      text-align: center;
      font-weight: bold;
    }
    h4 {
      color: white;
      font-weight: bold;
      text-align: center;
      font-size: 1.5rem;
      margin-top: 0;
      margin-bottom: 20px;
    }
    .download-links {
      font-size: 1.3125rem;
      & > .row {
        margin-right: -10px;
        margin-left: -10px;
        & > .col-sm-4 {
          padding-right: 10px;
          padding-left: 10px;
        }
      }
      a {
        display: inline-block;
        border: 2px solid $h-light-blue;
        width: 100%;
        text-align: center;
        padding: 18px 0;
        background-repeat: no-repeat;
        color: white;
        position: relative;
        padding-left: 55px;
        margin-bottom: 10px;
        &:hover {
          text-decoration: none;
        }
        img {
          position: absolute;
          top: 8px;
          left: 12px;
          height: calc(100% - 16px);
        }
      }
    }
  }
  .app-features {
    color: $h-blue;
    padding-top: 0;
    padding-bottom: 0;
    .circle-border-top {
      background-image: linear-gradient(90deg, #164891, #082861);
      width: 100%;
      height: 300px;
      position: absolute;
      top: -5px;
      left: 0;
      &:before {
        content: '';
        display: block;
        width: calc(100% + 200px);
        height: 100%;
        margin-left: -100px;
        margin-right: -100px;
        background: #fafbfd;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
      }
    }
    .wave {
      position: absolute;
      z-index: -1;
      height: 500px;
      width: 100%;
      background: #fafbfd;
      &:before {
        content: "";
        display: block;
        position: absolute;
        border-radius: 100% 50%;
        width: calc(50% + 100px);
        height: 300px;
        background-color: #fff;
        right: -60px;
        top: 366px;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        border-radius: 100% 50%;
        width: calc(50% + 100px);
        height: 300px;
        background-color: #fafbfd;
        left: -37px;
        top: 316px;
      }
    }
    h2 {
      font-weight: bold;
      font-size: 2.875rem;
      line-height: 1.1;
      text-transform: uppercase;
      margin-top: 100px;
      margin-bottom: 3rem;
    }
    h4 {
      font-size: 1.125rem;
      text-transform: none;
      font-weight: bold;
      color: $h-blue;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      span {
        display: block;
        border-bottom: 1px solid $grey-color-light;
        padding-bottom: 6px;
      }
    }
    ul {
      li {
        font-size: 1.0625rem !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
    img {
      margin-top: -40px;
    }
  }
  .app-features-comming {
    color: $h-blue;
    font-size: 1.3125rem;
    h2 {
      font-weight: bold;
      font-size: 2.875rem;
      text-transform: uppercase;
      margin-bottom: 2rem;
      line-height: 1.1;
    }
    .features {
      margin-top: 2rem;
    }
    h4 {
      font-size: 1.125rem;
      text-transform: none;
      font-weight: bold;
      color: $h-blue;
      span {
        display: block;
        border-bottom: 1px solid $grey-color-light;
        padding-bottom: 6px;
      }
    }
    .circle-border-bottom {
      background-image: linear-gradient(90deg, #164891, #082861);
      width: 100%;
      height: 300px;
      position: absolute;
      bottom: -2px;
      &:before {
        content: '';
        display: block;
        width: calc(100% + 200px);
        height: 100%;
        margin-left: -100px;
        margin-right: -100px;
        background: #fafbfd;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
      }
      .wave {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        &:before {
          content: '';
          display: block;
          position: absolute;
          border-radius: 50% 100%;
          width: calc(50% + 117px);
          height: 300px;
          background-color: #fafbfd;
          left: -35px;
          top: -85px;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          border-radius: 50% 100%;
          width: calc(50% + 100px);
          height: 300px;
          background-color: #fff;
          right: -55px;
          top: -154px;
        }
      }
    }
  }
  section.content.intro.download-bottom {
    padding-bottom: 6rem;
    padding-top: 6rem;
  }
  .site-footer {
    margin-top: -2px;
  }
}
@media (min-width: 992px) {
  #spherebyhorizen {
    .app-features {
      h4 {
        span {
          width: 80%;
        }
      }
    }
    .app-features-comming {
      h4 {
        span {
          width: 70%;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  #spherebyhorizen {
    .interior-header {
      min-height: 250px;
      padding: 0;
      .page-title {
        width: 60%;
        bottom: -6px;
      }
    }
    section.content.intro {
      padding-top: 2rem;
    }
    .app-features {
      img {
        margin-top: -10px;
      }
    }
  }
}

@media (max-width: 767px) {
  #spherebyhorizen {
    .app-features {
      h2 {
        margin-top: 10px;
      }
    }
  }
}

#media {
  .interior-header {
    background: url(../img/bkg/mediakit.jpg) no-repeat;
    background-size: cover;
  }
  .btn-download {
    background-image: linear-gradient(to right, #0e9de5, #26db8d);
    padding: 1rem 3rem;
    color: white;
    &:hover {
      color: white;
    }
  }
  .section-title {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    span {
      font-size: 1rem;
      font-weight: normal;
      text-transform: none;
      display: block;
      line-height: 1;
    }
  }
  .guideline-title {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid #e8e8e8;
    margin-top: 4rem;
    margin-bottom: 1.75rem;
  }
  .guideline-overview {
    background-color: #f1f2f5;
    color: #041742;
    padding-top: 5rem;
    .guideline-clear-space {
      & > .row {
        margin-right: -30px;
        margin-left: -30px;
        & > div {
          padding-right: 30px;
          padding-left: 30px;
        }
      }
    }
    .guideline-brand-name {
      .text-description {
        font-size: .85rem;
      }
      .row {
        .row {
          margin-right: -5px;
          margin-left: -5px;
          .col-6, .col-4 {
            padding-right: 5px;
            padding-left: 5px;
          }
        }
      }
    }
    .guideline-avoid {
      p {
        font-size: .85rem;
      }
    }
    .guideline-colors {
      .color-preview {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .color-view {
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 100%;
      }
      .color-dark-blue {
        .color-view {
          background-color: #041742;
        }
      }
      .color-green {
        .color-view {
          background-color: #26db8d;
        }
      }
      .color-light-blue {
        .color-view {
          background-color: #0e9de5;
        }
      }
      .gradient-view {
        width: 100%;
        height: 60px;
        border-radius: 30px;
      }
      .gradient1 {
        .gradient-view {
          background-image: linear-gradient(to right, #041742, #0e9de5);
        }
      }
      .gradient2 {
        .gradient-view {
          background-image: linear-gradient(to right, #0e9de5, #26db8d);
        }
      }
    }
  }
  .guideline-print-assets {
    background: white url("../img/icons/page_media/print_asset1.png") no-repeat;
    background-size: cover;
    padding: 5rem 0 3rem;
    @media (max-width: 991px) {
      background: white;
    }
    .guideline-title {
      color: #1B2F5A;
      letter-spacing: normal;
      margin-top: 0;
      border-bottom: none;
    }
    @media (min-width: 992px) {
      .img-asset {
        margin-top: 10rem;
      }
    }
  }
  .cta {
    background-image: linear-gradient(#f1f2f5, white);
    padding: 5rem 0;
    .icon-info {
      width: 40px;
    }
  }
  .forum-cta {
    margin-top: 0;
  }
}
