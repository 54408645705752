.language-sidebar,
.language-select,
#blog .card-body .card-title {
  font-family: sans-serif;
}
.lng-fr {
  .site-header .navbar-brand:not(.toggle-languages),
  .site-header .nav-link:not(.toggle-languages) {
    text-transform: none;
  }
  .jumbotron .btn-lg {
    padding: 1rem;
  }
  #tech {
    .stats-section {
      .stats {
        .stats-headline {
          word-spacing: 0;
        }
      }
    }
  }
}
.lng-nl {
  .jumbotron .btn-lg {
    padding: 1rem;
  }
  #tech {
    .tech-header {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
      h2.section-title {
        font-size: 18px;
      }
    }
    .stats-section {
      .circle-dots {
        h2 {
          font-size: 18px;
        }
      }
      .stats {
        .stats-headline {
          word-spacing: 0;
        }
      }
    }
  }
}
.lng-de {
  word-wrap: break-word;
  #tech {
    .stats-section {
      .circle-dots {
        h2 {
          font-size: 16px;
        }
      }
      .stats {
        .stats-headline {
          word-spacing: 0;
        }
      }
    }
  }
}
.lng-it {
  #tech {
    .stats-section {
      .circle-dots {
        h2 {
          font-size: 14px;
        }
      }
      .stats {
        .stats-headline {
          word-spacing: 0;
        }
      }
    }
  }
}
.lng-es {
  #tech {
    .stats-section {
      .circle-dots {
        h2 {
          font-size: 12px;
        }
      }
    }
  }
}
.lng-ko {
  #about {
    .logo-text {
      line-height: 1.4;
    }
  }
}
.lng-ru {
  #tech {
    .stats-section {
      .circle-dots {
        h2 {
          font-size: 13px;
        }
      }
    }
  }
  @media (max-height: 900px) and (min-width: 992px) {
    .jumbotron.newsletter-visible .btn-lg {
      padding: 1rem;
    }
  }
  .btn-lg {
    padding: 1rem;
  }
}
.lng-es,
.lng-jp {
  .btn-lg,
  .btn-group-lg > .btn {
    letter-spacing: 0;
  }
}
.lng-zh {
  #overview {
    h4 {
      font-size: 1.2rem;
    }
  }
  #features {
    h2 {
      font-size: 2rem;
    }
    .btn-white {
      font-weight: 700;
    }
  }
  #white-paper {
    .content {
      header, main {
        font-weight: 700;
      }
    }
  }
  .community-columns {
    .fa-weixin {
      color: $h-light-blue;
    }
  }
}
.lng-jp,
.lng-ko,
.lng-zh {
  h3 {
    &.subsection-title {
      font-size: 2.4rem;
      font-weight: 700;
    }
  }
  section {
    &.content {
      h2 {
        &:not(.lined-subtext) {
          font-size: 2rem;
        }
      }
    }
  }
  .btn {
    font-size: 1rem;
  }
  .btn-lg {
    font-size: 1rem;
    padding: 1rem 2rem;
  }
  .body-link {
    h3 {
      font-size: 2rem;
    }
  }
  #infographic {
    h2 {
      font-size: 4rem;
    }
  }
  @media (max-width: 575px) {
    #about {
      .body-link {
        h3 {
          font-size: 1.2rem;
        }
      }
    }
  }
}
